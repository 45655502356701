<!--
Page Title

Renders a full-width page title with an icon.
-->
<template>
	<div class="pt-container">

		<v-icon v-if="icon" :class="icon" class="mr-4" color="accent" size="48"/>

		<app-text category="text-xlarge" class="primary--text">{{ pageTitle }}</app-text>

	</div>
</template>

<script>
export default {
	props: ['icon', 'pageTitle'],
}
</script>

<style>
.pt-container {
	display: flex;
	align-items: center;

	margin-bottom: 16px;
	width: 100%;
}
</style>
