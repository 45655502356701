<template>

	<!--<v-col class="pa-4 d-flex justify-center align-center" cols="12">-->

	<!--	&lt;!&ndash;Title&ndash;&gt;-->
	<!--	<app-text category="text-default">-->
	<!--		{{ photoViewer.title.slice(0, 40).concat('...') }}-->
	<!--	</app-text>-->

	<!--	<v-spacer/>-->

	<!--	&lt;!&ndash;Buttons&ndash;&gt;-->
	<!--	&lt;!&ndash; <appbutton :label="$t('buttons.delete')" icon="icons8-download" type="accent"  class="mr-1" @click.native="MIX_deleteFile(photoViewer.photos[photoViewer.index].fileURL, photoViewer.photos[photoViewer.index].fileName)" /> &ndash;&gt;-->

	<!--	&lt;!&ndash;<appbutton :label="$t('buttons.download')" icon="icons8-download" type="accent" class="mr-1"&ndash;&gt;-->
	<!--	&lt;!&ndash;		   @click.native="MIX_downloadFile(photoViewer.photos[photoViewer.index].fileURL, photoViewer.photos[photoViewer.index].fileName)"/>&ndash;&gt;-->
	<!--</v-col>-->

	<!--Carousel-->
	<v-carousel hide-delimiters v-model="photoViewer.index"
				:height="photoViewer.height"
				:width="photoViewer.width">

		<v-carousel-item
			v-for="(item,i) in photoViewer.photos"
			:key="i"
			:src="item.fileURL">
		</v-carousel-item>

	</v-carousel>

</template>

<script>
import {mapGetters} from 'vuex';

export default {
	// * DATA
	data: () => ({
		photoViewer: {
			title: '',
			index: 0,
			photos: [],
			width: '',
			height: '',
		},
	}),
	// * COMPUTED
	computed: {
		...mapGetters({
			GET_photoViewer: 'photoViewer_store/GET_photoViewer'
		})
	},
	// * METHOD
	methods: {},
	// * WATCH
	watch: {
		GET_photoViewer: {
			handler() {
				this.photoViewer = this.GET_photoViewer;
			}, deep: true
		}
	},
	// * MOUNTED
	mounted() {
		this.photoViewer = this.GET_photoViewer;
	}

}
</script>
