<template>
    <v-list nav dense class="primary">

        <v-list-item v-for="(item, index) in computedTabs"
                     :key="index"
                     link
                     @click="go(item.path, item.name)"
                     active-class="darkgrey"
                     class="list-item">

            <v-list-item-icon class="white--text">
                <v-icon class="navicons white--text">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title class="white--text text-left">{{ item.name }}</v-list-item-title>

        </v-list-item>

    </v-list>

</template>

<script>
import {mapGetters} from 'vuex'; // Map the Getters from the Vuex Store

export default {

    name: "MenuList",

    data: () => ({
        tabs: [
            {name: 'Organisations', path: "/organisations", icon: "icons8-business-building", userLevels: ['SA']},
            {name: 'My Organisation', path: "/myOrganisation", icon: "icons8-business-building", userLevels: ['CA']},
            {name: 'Users', path: "/staff-directory", icon: "icons8-people", userLevels: ['SA']},
            // {name: 'Map', path:"/map", icon:"icons8-map-marker", userLevels: ['CU', 'CA', 'SA'] },
            {name: 'My Profile', path: "/userProfile", icon: "icons8-customer", userLevels: ['CU', 'CA', 'SA']},
            {name: 'App Support', path: "/appSupport", icon: "icons8-online-support", userLevels: ['CU', 'CA', 'SA']},
            {name: 'Settings', path: "/settings", icon: "icons8-settings", userLevels: ['DA']},
        ],
        langPath: 'menuItems'
    }),

    computed: {
        ...mapGetters({
            GET_mobileNav: 'GET_mobileNav',
            GET_currentUser: 'GET_currentUser'
        }),

        computedTabs() {
            const t = this
            const tabs = t.tabs
            const newTabs = []

            // If the current user has access, display the link
            tabs.forEach(function (tab) {
                if (tab.userLevels.includes(t.GET_currentUser.userLevel)) {

                    // Only show Fire Roll Call to Admins and those with access
                    if (tab.name === 'Fire Roll Call') {
                        if (t.GET_currentUser.userLevel !== 'SA' && !t.GET_currentUser.userAccessAndAbilities.includes('Fire Marshall')) {
                            return
                        }
                    }

                    // Only show Covid to those with access
                    if (tab.name === 'Covid') {
                        if (!t.GET_currentUser.userAccessAndAbilities.includes('Covid')) {
                            return
                        }
                    }

                    newTabs.push(tab)
                }
            })

            return newTabs
        }
    },

    methods: {
        // // updateBreadcrumbs(name, path) {
        // //     var t = this;
        // //     var breadcrumbs = [];
        // //     var uperrcaseName = t.MIX_capitalizeText(name);
        // //     breadcrumbs.push ({ name: uperrcaseName, path: path}) // Set the Main Path
        // //     t.MIX_breadcrumbs(breadcrumbs);
        // // },

        go(path, name) {
            this.$router.push(path).catch((err) => {
                console.log("Route error: " + err)
            })
            this.MIX_mobileNav(false)
            //// this.updateBreadcrumbs(name, path);
        },
    }

}
</script>

<style scoped>

.list-item {
    transition: 0.3s
}

.list-item:hover {
    background: var(--v-accent-base)
}

.navicons {
    font-size: 24px !important;
    font-weight: bold !important;
}

.v-tab--active {
    background-color: var(--v-primary-base) !important;
}
</style>
