<!--
ConfirmBox Component

Renders a Yes/No confirmation box with a title, message and buttons.
-->
<template>
	<v-dialog
		ref="dialog"
		v-model="GET_confirmBox.show"
		:max-width="GET_confirmBox.width"
		:max-height="GET_confirmBox.height">

		<v-card ref="popup" class="appbackground" :style="dynamicStyle">

			<!--Title bar-->
			<div class="primary d-flex align-center" style="height: 50px !important;">
				<!--Title-->
				<app-text category="text-default" class="white--text ma-4">{{ GET_confirmBox.title }}</app-text>

				<v-spacer/>

				<!--Close icon-->
				<appbutton icon="icons8-multiply" type="primary" iconclass="white--text" @click.native="confirmResult(GET_confirmBox.falseButtonValue)"/>
			</div>

			<!--Message-->
			<div class="pa-4">
				<app-text category="text-default"><span v-html="GET_confirmBox.description"></span></app-text>
			</div>

			<!--Buttons-->
			<div class="pa-4 pt-0 d-flex">
				<appbutton :label="GET_confirmBox.falseButtonText" type="grey ligthen-2" @click.native="confirmResult(GET_confirmBox.falseButtonValue)"/>
				<v-spacer/>
				<appbutton :label="GET_confirmBox.trueButtonText" type="error" @click.native="confirmResult(GET_confirmBox.trueButtonValue)"/>
			</div>

		</v-card>

	</v-dialog>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import App_button from '../../components/base/app_button.vue';
import app_text from '../../components/base/app_text.vue';

export default {
	components: {app_text, App_button},
	computed: {
		...mapGetters({
			GET_confirmBox: 'confirmBox_store/GET_confirmBox'
		}),
		dynamicStyle() {
			return `width: ${this.GET_confirmBox.width}px !important; min-width: ${this.GET_confirmBox.width}px !important; max-width: ${this.GET_confirmBox.width}px !important; height: ${this.GET_confirmBox.height}px !important; min-height: ${this.GET_confirmBox.height}px !important; max-height: ${this.GET_confirmBox.height}px !important; `
		}
	},
	methods: {
		...mapActions({
			ACT_confirmBoxResult: 'confirmBox_store/ACT_confirmBoxResult',
		}),
		confirmResult(result) {
			var payload = {}
			payload.code = 1
			payload.message = ''
			payload.data = {}
			payload.data.result = result;
			payload.data.collection = this.GET_confirmBox.collection;
			payload.data.id = this.GET_confirmBox.id;
			this.ACT_confirmBoxResult(payload);
			this.closeDialog();
		},
		closeDialog() {
			this.MIX_confirmBox({
				show: false,
				collection: '',
				title: '',
				description: '',
				width: this.GET_confirmBox.width,
				height: this.GET_confirmBox.height,
				trueButtonText: '',
				trueButtonValue: '',
				falseButtonText: '',
				falseButtonValue: '',
			})
		}
	},
}
</script>
