<!--
Suspended
Renders a page for user's with accounts that are archived/suspened, preventing access to the app.
The page should display an email address of an administrator for suspended users to contact.
-->
<template>
    <v-row class="pa-4" no-gutters>

        <!--Page title-->
        <page-title :icon="computedPageTitle.icon" :page-title="computedPageTitle.title"/>

        <v-col cols="12">
            <v-divider/>
        </v-col>

        <!--Welcome | Information-->
        <v-col cols="12" sm="10" md="8" lg="6">
            <v-card class="rounded-lg mt-4 pa-4" flat>

                <!--Welcome-->
                <app-text category="text-default-bold">
                    Welcome {{ computedUserName }}
                </app-text>

                <!--Information-->
                <app-text category="text-default" class="mt-4">
                    {{ computedStatusMessage }}
                </app-text>

                <!--Instructional text-->
                <app-text category="text-default" class="mt-4">
                    {{ computedInstructionalText }}
                </app-text>

            </v-card>
        </v-col>

    </v-row>
</template>

<script>
export default {
    name: "suspended",
    data: () => ({
    }),
    computed: {
        /**
         * Computed Page Title
         *
         * Returns an icon and page title based on the user's status.
         *
         * @returns {{icon: string, title: string}} - icon and title for page header
         */
        computedPageTitle() {
            const t = this
            let pageTitle = {icon: '', title: ''}
             if (t.GET_currentUser.delete) {
                pageTitle.icon = 'icons8-private'
                pageTitle.title = 'Deleted'
            } else if (t.GET_currentUser.userStatus.toUpperCase() === 'SUSPENDED') {
                pageTitle.icon = 'icons8-private'
                pageTitle.title = 'Suspended'
            } else if (t.GET_currentUser.userStatus.toUpperCase() === 'ARCHIVED') {
                pageTitle.icon = 'icons8-private'
                pageTitle.title = 'Archived'
            } else if (t.GET_currentUser.userStatus.toUpperCase() === 'REJECTED') {
                pageTitle.icon = 'icons8-private'
                pageTitle.title = 'Rejected'
            }

            return pageTitle
        },
        /**
         * Computed User Name
         *
         * Returns the current user's first name from their stored userName.
         *
         * @returns {name} - the current user's first name.
         */
        computedUserName() {
            const t = this
            return t.GET_currentUser.userName.split(' ')[0]
        },
        /**
         * Computed Status Message
         *
         * Returns an information message based, on the user's status.
         *
         * @returns {string} -
         */
        computedStatusMessage() {
            const t = this
            let message = ''

            if (t.GET_currentUser.delete) {
                message = 'Your account has been deleted.'
            } else if (t.GET_currentUser.userStatus.toUpperCase() === 'SUSPENDED') {
                message = 'Your account has been suspended.'
            } else if (t.GET_currentUser.userStatus.toUpperCase() === 'ARCHIVED') {
                message = 'Your account has been archived.'
            } else if (t.GET_currentUser.userStatus.toUpperCase() === 'REJECTED') {
                message = 'Your account has been rejected.'
            } else {
                t.MIX_go('/')
            }
            return message
        },
        /**
         * Computed Instructional Text
         *
         * Returns an information message, based on the user's status.
         *
         * @returns {string} -
         */
        computedInstructionalText() {
            const t = this
            let message = `Please contact the following administrator to recover access to your account: chris@vindico.net`       
            return message
        }
    },
    mounted() {
        const t = this
        t.$firebase.db
            .collection('users')
            .doc(t.GET_currentUser.id)
            .onSnapshot(doc => {
                // Listen for changes to, and set, the user status
                t.userStatus = doc.data().userStatus
            });
    }
}
</script>