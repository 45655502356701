var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-0 px-4 "},[_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0 mb-4",attrs:{"cols":_vm.$vuetify.breakpoint.width <= 600 ? 12 : 6}},[_c('app-input',{attrs:{"input-type":"textInput","clearable":"","label":_vm.$t('filters.search'),"append-icon":'icons8-search',"placeholder":"Search by name, telephone, email or status"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),(_vm.$vuetify.breakpoint.width >= 600)?_c('v-data-table',{staticClass:"rounded-lg",attrs:{"headers":_vm.computedHeaders,"items":_vm.filteredTableData,"search":_vm.search,"sort-by":"userName"},scopedSlots:_vm._u([{key:"item.userName",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.userName))])]}},{key:"item.userTelephone",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.userTelephone))])]}},{key:"item.userEmail",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.userEmail))])]}},{key:"item.userStatus",fn:function(ref){
var item = ref.item;
return [(item.userStatus === 'APPROVED')?_c('v-icon',{staticClass:"icons8-checkmark-yes",attrs:{"color":"success","size":"32"}}):_vm._e(),(item.userStatus === 'ARCHIVED')?_c('v-icon',{staticClass:"icons8-trash",attrs:{"color":"grey","size":"32"}}):_vm._e(),(item.userStatus === 'PENDING')?_c('v-icon',{staticClass:"icons8-high-importance",attrs:{"color":"orange","size":"32"}}):_vm._e(),(item.userStatus === 'REJECTED')?_c('v-icon',{staticClass:"icons8-cancel",attrs:{"color":"error","size":"32"}}):_vm._e(),(item.userStatus === 'SUSPENDED')?_c('v-icon',{staticClass:"icons8-private",attrs:{"color":"error","size":"32"}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.actionsList),function(item1,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateStatus(item, item1.action)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":item1.color,"size":"32"},domProps:{"textContent":_vm._s(item1.icon)}})],1),_c('v-list-item-title',[_vm._v(_vm._s(item1.action))])],1)}),1)],1)],1)]}}],null,false,2027961056)}):_vm._e(),(_vm.$vuetify.breakpoint.width < 600)?_c('div',_vm._l((_vm.filteredTableData),function(member){return _c('v-card',{key:member.id,staticClass:"rounded-lg mb-4 pa-4",attrs:{"flat":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('app-text',{staticClass:"mb-2",attrs:{"category":"text-default-bold"}},[_vm._v(" "+_vm._s(member.userName)+" ")]),(member.userRole === 'Admin')?_c('app-text',{staticClass:"mb-2 ml-2 grey--text",attrs:{"category":"text-default"}},[_vm._v(" ("+_vm._s(member.userRole)+") ")]):_vm._e()],1),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"icons8-email mr-2",attrs:{"color":"primary","size":"16"}}),_c('app-text',{staticClass:"grey--text",attrs:{"category":"text-default"}},[_vm._v(" "+_vm._s(member.userEmail)+" ")])],1)])])}),1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"512","transition":"dialog-bottom-transition"},model:{value:(_vm.confirmationDialog),callback:function ($$v) {_vm.confirmationDialog=$$v},expression:"confirmationDialog"}},[_c('my-organisation-status-confirmation-dialog',{attrs:{"userItem":_vm.userToBeModified && _vm.userToBeModified,"status":_vm.userStatusToBeModified && _vm.userStatusToBeModified},on:{"emitCloseDialog":_vm.emittedCloseDialog,"emitStatusConfirmation":_vm.emittedStatusConfirmation}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }