<!--
User

Renders the form (right side) of Users to add/edit/delete users.
-->
<template>
    <div style="width: 100%">

        <!--Header-->
        <div class="d-flex pa-4">

            <!--Username and Status-->
            <div>

                <!--Pending-->
                <app-text v-if="form.userStatus === 'PENDING'" category="text-default" class="mt-1">
                    <v-icon class="icons8-inactive-state mb-1 mr-2" color="error" size="32"/>
                    {{ form.userName }}
                </app-text>

                <!--Approved-->
                <app-text v-if="form.userStatus === 'APPROVED'" category="text-default" class="mt-1">
                    <v-icon class="icons8-checkmark-yes mb-1 mr-2" color="success" size="32"/>
                    {{ form.userName }}
                </app-text>

                <!--Suspended-->
                <app-text v-if="form.userStatus === 'SUSPENDED'" category="text-default" class="mt-1">
                    <v-icon class="icons8-private mb-1 mr-2" color="error" size="32"/>
                    {{ form.userName }}
                </app-text>

                <!--Archived-->
                <app-text v-if="form.userStatus === 'ARCHIVED'" category="text-default" class="mt-1">
                    <v-icon class="icons8-xbox-a mb-1 mr-2" color="grey" size="32"/>
                    {{ form.userName }}
                </app-text>

            </div>

            <v-spacer/>

            <!--Action buttons (edit | delete)-->
            <div class="d-flex align-center">

                <edit-icon v-if="formMode !== 'New'" @click.native="editItem()"/>

                <!--Delete-->
                <v-icon v-if="formMode !== 'New' && form.delete === undefined"
                        @click.native="deleteItem(form.id)"
                        class="icons8-trash frc-icon"
                        color="error"
                        size="32"/>

            </div>

            <!--User status action buttons-->
            <div class="d-flex">

                <!--Approve-->
                <appbutton v-if="form.userStatus === 'PENDING' && formMode !== 'Edit' && formMode !== 'New'"
                           label="Approve" icon="icons8-checkmark-yes" type="success" class="ma-0"
                           @click.native="updateItemStatus('APPROVED')" showlabel="xlOnly"/>

                <!--Reject-->
                <appbutton v-if="form.userStatus === 'PENDING' && formMode !== 'Edit' && formMode !== 'New'"
                           label="Reject" icon="icons8-high-importance" type="warning" class="ma-0"
                           @click.native="updateItemStatus('REJECTED')" showlabel="xlOnly"/>

                <!--Suspend-->
                <!--<appbutton v-if="form.userStatus !== 'SUSPENDED' && formMode !== 'Edit' && formMode !== 'New'"
                label="Suspend" icon="icons8-private" type="error" class="ma-0" @click.native="updateItemStatus('SUSPENDED')" showlabel="xlOnly" />-->

                <!--Restore-->
                <appbutton v-if="form.userStatus === 'SUSPENDED' && formMode !== 'Edit' && formMode !== 'New'"
                           label="Restore" icon="icons8-process" type="success" class="ma-0"
                           @click.native="updateItemStatus('APPROVED')" showlabel="xlOnly"/>

            </div>

        </div>

        <!--Tabs-->
        <v-tabs v-model="tabs" :height="buttonSizeDefault">

            <v-tabs-slider color="accent"/>

            <!--Overview-->
            <v-tab href="#overview">
                <app-text category="text-small">Overview</app-text>
            </v-tab>

        </v-tabs>

        <!--Tabs Content-->
        <v-tabs-items v-model="tabs" class="appbackground">

            <!--Overview tab content-->
            <v-tab-item value="overview">

                <!--Form-->
                <v-row class="pa-4" no-gutters>

                    <!--Profile Picture | User Details-->
                    <v-row no-gutters style="width: 100%">

                        <!--Profile picture-->
                        <v-col
                            class="d-flex justify-center align-center pa-2 rounded-lg white profileImage-container"
                            :class="$vuetify.breakpoint.width >= 600 ? 'flex-grow-0 mr-4' : ''"
                            :cols="$vuetify.breakpoint.width < 600 && 12"
                            style="height: 272px; width: 272px">

                            <!--If an image is present, render it-->
                            <div v-if="form.profilePicFileURL && !tempImage"
                                 class="d-flex flex-column align-center">

                                <!--Image-->
                                <v-img :src="form.profilePicFileURL"
                                       class="rounded-lg" cover height="254" width="254"/>

                                <!--Upload button-->
                                <div v-if="formMode === 'Edit'"
                                     style="position: absolute; z-index: 9; margin-top: 200px">

                                    <photoupload class="mr-n4"
                                                 allowedtypes="image/*"
                                                 :docLink="{collection: 'users', documentId: form.id}"
                                                 folder="users-profile-pictures"/>

                                </div>

                            </div>

                            <!--If a tempImage (upload preview) is present, render it-->
                            <div v-else-if="tempImage"
                                 class="d-flex flex-column align-center">

                                <!--Image-->
                                <v-img :src="tempImage"
                                       class="rounded" cover height="254" width="254"/>

                                <!--Upload button-->
                                <div style="position: absolute; z-index: 9; margin-top: 200px">

                                    <photoupload class="mr-n4"
                                                 allowedtypes="image/*"
                                                 :docLink="{collection: 'users', documentId: form.id}"
                                                 folder="users-profile-pictures"/>

                                </div>

                            </div>

                            <!--If neither an image or tempImage is present, render an icon-->
                            <div v-else class="d-flex flex-column align-center" style="width: 254px">

                                <!--Image-->
                                <v-icon class="icons8-customer" size="254"/>

                                <!--Upload button-->
                                <div v-if="formMode === 'New' || formMode === 'Edit'"
                                     style="position: absolute; z-index: 9; margin-top: 200px">

                                    <photoupload class="mr-n4"
                                                 style="width: 100%"
                                                 allowedtypes="image/*"
                                                 :docLink="{collection: 'users', documentId: form.id}"
                                                 folder="users-profile-pictures"/>

                                </div>

                            </div>

                        </v-col>

                        <!-- User details -->
                        <v-col :class="$vuetify.breakpoint.width >= 600 && 'flex-grow-1'"
                               :cols="$vuetify.breakpoint.width < 600 && 12">

                            <!--Name-->
                            <app-input input-type="textInput"
                                       :class="$vuetify.breakpoint.width < 600 && 'mt-4'"
                                       :error="errors.userName"
                                       :error-messages="errors.userNameErrorMessage"
                                       :is-form-read-only="formReadOnly"
                                       label="Full Name"
                                       v-model.trim="form.userName"/>

                            <!--Job Title-->
                            <app-input input-type="textInput"
                                       class="mt-4"
                                       :error="errors.userJobTitle"
                                       :error-messages="errors.userJobTitleErrorMessage"
                                       :is-form-read-only="formReadOnly"
                                       label="Job Title"
                                       v-model.trim="form.userJobTitle"/>

                            <!--Email-->
                            <app-input input-type="textInput"
                                       class="mt-4"
                                       :error="errors.userEmail"
                                       :error-messages="errors.userEmailErrorMessage"
                                       :is-form-read-only="formReadOnly"
                                       label="Email"
                                       v-model.trim="form.userEmail"/>

                            <!--Telephone-->
                            <app-input input-type="textInput"
                                       class="mt-4"
                                       :error="errors.userTelephone"
                                       :error-messages="errors.userTelephoneErrorMessage"
                                       :is-form-read-only="formReadOnly"
                                       label="Telephone"
                                       v-model="form.userTelephone"/>

                        </v-col>

                    </v-row>

                    <!-- Configuration (User Type | User Role | User Status) -->
                    <v-col class="mt-4" cols="12">
                        <app-text category="text-medium" class="darkgrey--text">Configuration</app-text>
                        <v-divider class="mt-2"/>
                    </v-col>

                    <!--User type-->
                    <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <app-input input-type="select"
                                   class="mt-4"
                                   :class="$vuetify.breakpoint.width >= 600 && 'pr-2'"
                                   :is-form-read-only="formReadOnly"
                                   :items="userTypesOptionsData"
                                   label="User type"
                                   v-model="form.userType">
                            <template v-slot:item="data">
                                <app-text category="text-default">{{ data.item }}</app-text>
                            </template>
                        </app-input>
                    </v-col>

                    <!--User role-->
                    <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <app-input input-type="select"
                                   class="mt-4"
                                   :class="$vuetify.breakpoint.width >= 600 && 'px-2'"
                                   :is-form-read-only="formReadOnly"
                                   :items="computedUserRoles"
                                   label="User Role"
                                   v-model="form.userRole">
                            <template v-slot:item="data">
                                <app-text category="text-default">{{ data.item }}</app-text>
                            </template>
                        </app-input>
                    </v-col>

                    <!--User status-->
                    <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <app-input input-type="select"
                                   class="mt-4"
                                   :class="$vuetify.breakpoint.width >= 600 && 'pl-2'"
                                   :is-form-read-only="formReadOnly"
                                   :items="userStatusOptionsData"
                                   label="User Status"
                                   v-model="form.userStatus">
                            <template v-slot:item="data">
                                <app-text category="text-default">{{ data.item }}</app-text>
                            </template>
                        </app-input>
                    </v-col>

                    <!--Save-->
                    <v-col cols="12" class="d-flex justify-end mt-4">
                        <app-btn v-if="formMode === 'Edit' || formMode === 'New'"
                                 @click.native="validateForm"
                                 color="success"
                                 icon="icons8-save"
                                 label="Save"/>
                    </v-col>

                </v-row>

                <!--Created and Modified info -->
                <v-col v-if="formMode !== 'New'" class="mb-4" cols="12">

                    <v-divider/>

                    <div>
                        <app-text category="text-small-bold" class="grey--text mt-4">
                            Created:
                            <span class="font-weight-regular">{{ form.createdUserData.userName
                                }} - {{ MIX_formatDateTime(form.createdDateTime, "x", "DD-MMM-YYYY")
                                }}</span>
                            |
                            Modified:
                            <span class="font-weight-regular">{{ form.modifiedUserData.userName
                                }} - {{ MIX_formatDateTime(form.modifiedDateTime, "x", "DD-MMM-YYYY")
                                }}</span>
                        </app-text>
                    </div>

                </v-col>

            </v-tab-item>

        </v-tabs-items>

    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"

export default {

    name: "StaffDirectory",

    data: () => ({
        collection: "users",
        collectionTitle: "Users",
        collectionItem: "user",
        collectionItemTitle: "Users",
        tabs: "overview",

        // * FORM RELATED
        errors: {
            userName: false,
            userNameErrorMessage: '',
            userJobTitle: false,
            userJobTitleErrorMessage: '',
            userEmail: false,
            userEmailErrorMessage: '',
            userTelephone: false,
            userTelephoneErrorMessage: '',

        },
        formMode: '',
        formReadOnly: true,
        formBackground: 'grey lighten-3',
        form: {
            id: '',
            authId: null,
            privacyPolicyConsent: false,
            profilePicFileURL: null,
            // userCategory: '',
            userEmail: '',
            userLevel: '',
            userName: '',
            userJobTitle: '',
            userRole: '',
            userStatus: '',
            userTelephone: '',
            userType: '',

            // Added from admin
            createdUserData: {},
            createdDateTime: '',
            modifiedUserData: {},
            modifiedDateTime: '',
        },
        // * PROFILE PICTURE
        types: "image/*",
        storagePathProfilePic: "users-profile-pictures",
        photoUploadResult: {},
        tempImage: '',

        //check if user was created in admin dashboard
        userInLocalStorage: false,
        userInLocalStorageId: null,

        filterStateItems: ["CURRENT", "ARCHIVED"],

    }),

    computed: {
        ...mapGetters({
            GET_openItem: "GET_openStaffDirectory",
            GET_panelLayout: "GET_panelLayout", // Panel Layout used for Layout Structure
            GET_openStaffDirectory: "GET_openStaffDirectory",
            GET_confirmBoxResult: "confirmBox_store/GET_confirmBoxResult", // Get Confirm Box Result
            GET_photoUploadResult: 'photoUpload_store/GET_photoUploadResult'
        }),

        /**
         * Computed User Roles
         *
         * Return the correct user roles for the specified user type.
         * @returns {Array} user roles as strings
         */
        computedUserRoles() {
            const t = this
            let roles = []
            const type = t.form.userType

            if (type === 'Staff') {
                roles = ['User', 'Admin']
            } else if (type === 'Client') {
                roles = ['User', 'Admin']
            }

            return roles
        },

    },

    methods: {
        ...mapActions({ACT_openItem: "ACT_openStaffDirectory", ACT_openStaffDirectory: "ACT_openStaffDirectory"}),

        /**
         * Validate
         *
         * Validates the required fields for presence only.
         * If any of the fields are missing mark them in an errors object.
         * When there are no errors left, save the record.
         */
        validateForm() {
            const t = this
            const emailRegex = /.+@.+\..+/

            t.errors.userName = false
            t.errors.userNameErrorMessage = ''
            t.errors.userJobTitle = false
            t.errors.userJobTitleErrorMessage = ''
            t.errors.userEmail = false
            t.errors.userEmailErrorMessage = ''
            t.errors.userTelephone = false
            t.errors.userTelephoneErrorMessage = ''

            // Name
            if (!t.form.userName.trim()) {
                t.errors.userName = true
                t.errors.userNameErrorMessage = 'Name required'
            }
            // Must be between 2 and 60 characters
            else if (t.form.userName.trim().length < 2 || t.form.userName.trim().length > 60) {
                t.errors.userName = true
                t.errors.userNameErrorMessage = 'Name must be between 2 and 60 characters'
            }

            // Job Title
            if (!t.form.userJobTitle.trim()) {
                t.errors.userJobTitle = true
                t.errors.userJobTitleErrorMessage = 'Job Title required'
            }

            // Email
            if (!t.form.userEmail.trim()) {
                t.errors.userEmail = true
                t.errors.userEmailErrorMessage = 'Email required'
            }
            // Must be a (simple) valid email address
            else if (!emailRegex.test(t.form.userEmail)) {
                t.errors.userEmail = true
                t.errors.userEmailErrorMessage = 'Email not valid'
            }

            // Telephone
            if (!t.form.userTelephone.trim()) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Telephone Number required'
            }
            // Landline number must start 01, 02 or 03 and be either 10 or 11 digits
            else if (['1', '2', '3'].includes(t.form.userTelephone.trim()[1]) && (t.form.userTelephone.trim().length < 10 || t.form.userTelephone.trim().length > 11)) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Landline numbers must have either 10 or 11 digits'
            }
            // Mobile number must start 07 and be 11 digits
            else if (['7'].includes(t.form.userTelephone.trim()[1]) && t.form.userTelephone.trim().length !== 11) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Mobile numbers must have 11 digits'
            }
            // Number must start 01, 02, 03 or 07
            else if (!['0'].includes(t.form.userTelephone[0]) || ['0', '4', '5', '6', '8', '9'].includes(t.form.userTelephone[1])) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Landline numbers start 01, 02 or 03. Mobile numbers must start 07'
            }

            // Check if there any errors left
            if (!Object.values(t.errors).includes(true)) {
                t.setUserLevel()
                t.saveItem()
            }
        },

        /**
         * Set User Level
         *
         * Configure the user's user level from their user type and user role.
         */
        setUserLevel() {
            const t = this

            t.form.userLevel = t.form.userType[0] + t.form.userRole[0];
        },

        async openItem() {
            const t = this

            const openItem = await t.GET_openItem

            switch (openItem.process) {
                case "New":
                    t.formMode = "New"
                    t.formReadOnly = false
                    t.formBackground = "white"
                    break
                case "View":
                    const item1 = await t.MIX_readDocumentById(t.collection, openItem.data.id)
                    t.form = item1.data
                    t.formMode = "View"
                    t.formReadOnly = true
                    t.formBackground = "grey lighten-3"
                    break
                case "Edit":
                    const item2 = await t.MIX_readDocumentById(t.collection, openItem.data.id)
                    t.form = item2.data
                    t.formMode = "Edit"
                    t.formReadOnly = false
                    t.formBackground = "white"
                    break
            }
        },

        async undeleteItem(id) {
            const t = this

            const undeleteResult = await t.MIX_undeleteDocument(t.collection, id)

            if (undeleteResult.code === 1) {
                t.MIX_alert(1, `${t.collectionItemTitle} Restored`, undeleteResult.data, null)
            } else {
                t.MIX_alert(-1, `Error Restoring ${t.collectionItemTitle}`, null, undeleteResult.error)
                console.log(`undeleteResult.error = ${JSON.stringify(undeleteResult.error, null, 2)}`)
            }

            await t.refreshItemView()
        },

        deleteItem(id) {
            const t = this
            const width = t.$vuetify.breakpoint.width <= 425 ? '' : '400'

            t.MIX_confirmBox({
                show: true,
                collection: t.collection,
                id: id,
                title: `Delete User`,
                description: `Are you sure you want to delete this user: <br /><div class="mt-4"><strong>${t.form[t.collectionItem + "Name"]}</strong>?</div>`,
                width,
                trueButtonText: "Yes",
                trueButtonValue: "YES",
                falseButtonText: "No",
                falseButtonValue: "NO",
            })
        },

        removeUploadedDocument() {
            this.$store.commit("SET_upload_file_store", null)
        },

        editItem() {
            const t = this

            if (t.formMode === "View") {
                t.formMode = "Edit"
                t.formReadOnly = false
                t.formBackground = "white"
            } else if (t.formMode === "Edit") {
                t.formMode = "View"
                t.formReadOnly = true
                t.formBackground = "grey lighten-3"
                t.tempImage = ''
                t.removeUploadedDocument()
                t.refreshItemView()
            }

        },

        async checkUserInLocalStorage(email) {
            const t = this

            return new Promise(function (resolve, reject) {
                t.$firebase.db
                    .collection("users")
                    .where("userEmail", "==", email)
                    .get()
                    .then(function (querySnapshot) {
                        if (querySnapshot.docs.length === 0) {
                            t.userInLocalStorage = false
                            return resolve(t.userInLocalStorage)
                        } else {
                            t.userInLocalStorage = true
                            t.userInLocalStorageId = querySnapshot.docs[0].id
                            return resolve(t.userInLocalStorage)
                        }
                    })
                    .catch(function (error) {
                        return reject(error)
                    })
            })
        },

        async saveItem() {
            const t = this

            // If form is NEW
            if (t.formMode === "New") {

                // Check if user already exists
                const userExists = await this.checkUserInLocalStorage(t.form.userEmail)

                if (userExists !== true) {
                    const createDocumentResult = await t.MIX_createDocument(t.collection, t.form)

                    if (createDocumentResult.code === 1) {
                        t.MIX_alert(1, `User successfully created`, null, null)
                        t.formMode = "View"
                        t.formReadOnly = true
                        t.formBackground = "grey lighten-3"

                        // Add uploaded profile image
                        await t.uploadProfileImage()

                        await t.refreshItemView(createDocumentResult.data.id)

                        t.removeUploadedDocument()

                    } else {
                        t.MIX_alert(-1, `Error creating user`, null, null)
                    }
                } else {
                    t.MIX_alert(-1, `This user already exists`, null, null)
                }
            }

            // If form is EDIT
            else if (t.formMode === "Edit") {
                const updateDocumentResult = await this.MIX_updateDocument(t.collection, t.form)
                if (updateDocumentResult.code === 1) {
                    t.MIX_alert(1, `User successfully updated`, updateDocumentResult.data, null)
                    t.formMode = "View"
                    t.formReadOnly = true
                    t.formBackground = "grey lighten-3"

                    // Add uploaded profile image
                    await t.uploadProfileImage()

                    await t.refreshItemView()

                } else {
                    t.MIX_alert(-1, "Error updating user", null, updateDocumentResult.error)
                }
            }
        },

        async refreshItemView(id) {
            const t = this

            // If no ID has been passed through, use the form ID
            if (!id) {
                id = t.form.id
            }

            const itemResult = await t.MIX_readDocumentById(t.collection, id)
            const payload = {}
            payload.process = "View"
            payload.popup = false
            payload.data = itemResult.data
            t.ACT_openItem(payload)
            await t.openItem()
        },

        async updateItemStatus(status) {
            const t = this

            const updateResult = await t.MIX_updateDocumentFieldsById(t.collection, t.form.id, {[t.collectionItem + "Status"]: status})

            if (updateResult.code === 1) {
                t.MIX_alert(1, "User status successfully updated", updateResult.data, null);
                await t.refreshItemView();
            } else {
                t.MIX_alert(-1, "Error updating user status", null, updateResult.error);
            }
        },

        /**
         * Upload Profile Image
         *
         * Upon successful saving/updating of a User document, also save the profile image path.
         *
         * @returns {Promise<void>}
         */
        async uploadProfileImage() {
            const t = this

            if (t.photoUploadResult !== {}) {

                // Save to the document with: collection | user id | image path
                const updatePhotosResult = await this.MIX_updateDocumentFieldsById(
                    'users', t.photoUploadResult.docLink, {profilePicFileURL: t.photoUploadResult.fileURL})

                // Render confirmation box
                if (updatePhotosResult.code === 1) {
                    this.MIX_alert(1, 'User successfully updated', updatePhotosResult.data, null)
                } else {
                    this.MIX_alert(-1, 'Error updating user', null, updatePhotosResult.error)
                }
            }
        },

    },

    watch: {

        GET_confirmBoxResult: {
            handler: async function () {
                const t = this

                if (t.GET_confirmBoxResult.data.collection === t.collection) {
                    if (t.GET_confirmBoxResult.data.result === "YES") {
                        var deleteResult = await t.MIX_deleteDocument(t.GET_confirmBoxResult.data.collection, t.GET_confirmBoxResult.data.id)
                        if (deleteResult.code === 1) {
                            t.MIX_alert(1, `${t.collectionItemTitle} Deleted`, deleteResult.data, null)
                            t.ACT_panelLayout({leftPanel: 12, rightPanel: 0, show: false, dynamicComponent: ""})
                        } else {
                            t.MIX_alert(-1, `Error Deleting ${t.collectionItemTitle}`, null, deleteResult.error)
                        }
                    }
                } else {
                    // Do nothing
                }
            },
            deep: true,
        },

        /**
         * Photo Upload Result
         *
         * On upload of a profile picture, add its storage path to the photoUploadResult variable.
         */
        GET_photoUploadResult: {
            handler: async function () {
                const t = this

                t.photoUploadResult = t.GET_photoUploadResult
                t.tempImage = t.photoUploadResult.fileURL

            }, deep: true
        },
    },

    async mounted() {
        const t = this

        t.removeUploadedDocument()

        const openItem = await t.GET_openItem
        t.formMode = openItem.process

        if (openItem.process === "New") {
            t.formReadOnly = false
            t.formBackground = "white"
        } else {
            t.form = openItem.data
            t.formReadOnly = true
            t.formBackground = "grey lighten-3"
        }
        if (openItem.popup === false) {

        }

    },

};
</script>

<style scoped>
.disabled {
    pointer-events: none;
    cursor: not-allowed;
}

.profileImage-container {
    border: 1px solid lightgray !important;
}

.expandtofill {
    display: block !important;
    width: 100% !important;
    height: 100% !important;
    /* background: #ffffff !important; */
}
</style>
