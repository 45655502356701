const progressBar_store = {
    namespaced: true, //to be case sensitive
    // * STATE
    state: {
        progressBar: {
            show: false,
        },
    },

    // * Actions
    actions: {
        ACT_progressBar ({commit}, payload){ commit('MUT_progressBar', payload) },
    },

    // * MUTATIONS
    mutations:{
        MUT_progressBar (state, payload) { state.progressBar = payload }
    },

    // * GETTERS
    getters:{
        GET_progressBar (state) { return state.progressBar }
    },
};
export default progressBar_store;
