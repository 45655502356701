<!--
CheckNetwork

Checks if the user is connected to the internet and shows a RED banner warning the user if they aren't.
Checks if the user has a slow internet connection (2g or slow-2g) and shows an ORANGE banner warning the user.

 -->
<template>
    <div>
        <v-overlay v-if="!onLine" color="grey" opacity="0.5" z-index="99998" />

        <v-alert v-if="!onLine"
                 class="d-flex"
                 icon="icons8-wi-fi-disconnected"
                 type="error">

            <app-text>
                To use this app you must be connected to WIFI
            </app-text>

        </v-alert>

        <v-alert v-if="(onLine && networkType) && (networkType === '2g' || networkType === 'slow-2g')"
                 class="d-flex"
                 icon="icons8-wi-fi-disconnected"
                 type="warning">

            <app-text>
                Your WIFI is slow, some things might not work
            </app-text>

        </v-alert>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // Detect if user has an internet connection
            onLine: navigator.onLine,

            // Detect network connection, eg. 2g / slow-2g
            networkType: false
        };
    },

    methods: {

        onConnectionChange() {
            this.networkType = navigator.connection.effectiveType
        },

        updateOnlineStatus(e) {
            const { type } = e;
            this.onLine = type === "online";
        },
    },

    mounted() {
        window.addEventListener("online", this.updateOnlineStatus)
        window.addEventListener("offline", this.updateOnlineStatus)

        if(navigator.connection) {
            this.networkType = navigator.connection.effectiveType
            navigator.connection.addEventListener('change', this.onConnectionChange)
        }
    },

    beforeDestroy() {
        window.removeEventListener("online", this.updateOnlineStatus)
        window.removeEventListener("offline", this.updateOnlineStatus)

        if(navigator.connection) {
            this.networkType = navigator.connection.effectiveType
            navigator.connection.removeEventListener('change', this.onConnectionChange)
        }
    },
}

</script>

<style>

.v-alert {
    border-radius: 0;
    bottom: 0;
    margin-bottom: 0;
    padding: 16px;
    position: fixed;
    width: 100%;
    z-index: 99999;
}

.v-alert .v-icon {
    align-self: center;
    font-size: 32px;
}

</style>
