var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[(_vm.$vuetify.breakpoint.width >= 600)?_c('v-row',{staticClass:"ma-0",attrs:{"no-gutters":""}},[_c('v-data-table',{staticClass:"rounded-lg",staticStyle:{"width":"100%"},attrs:{"headers":_vm.computedHeaders,"items":_vm.filteredTableData,"search":_vm.search,"sort-by":"deviceName"},scopedSlots:_vm._u([{key:"item.deviceName",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.deviceName))])]}},{key:"item.deviceId",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.deviceId))])]}},{key:"item.deviceType",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.deviceType))])]}},{key:"item.deviceLocation",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.deviceLocation))])]}},{key:"item.siteName",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.siteName))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-end",staticStyle:{"height":"100%"}},[_c('v-btn',{staticClass:"white text--red",attrs:{"depressed":""},nativeOn:{"click":function($event){return _vm.openDevice(item.id)}}},[_vm._v("Open "),_c('v-icon',{staticClass:"icons8-forward",attrs:{"color":"primary"}})],1)],1)]}}],null,false,2720079018)})],1):_vm._e(),_c('v-row',{staticClass:"ma-0",attrs:{"no-gutters":""}},[(_vm.$vuetify.breakpoint.width < 600)?_c('v-col',_vm._l((_vm.filteredTableData),function(device){return _c('div',{key:device.id},[_c('v-card',{staticClass:"rounded-lg mb-4 pa-4",attrs:{"flat":""},nativeOn:{"click":function($event){return _vm.openDevice(device.id)}}},[_c('app-text',{staticClass:"mb-2",attrs:{"category":"text-default-bold"}},[_vm._v(" "+_vm._s(device.deviceName)+" ")]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"icons8-person mr-2",attrs:{"color":"primary","size":"16"}}),_c('app-text',{staticClass:"grey--text mr-4",attrs:{"category":"text-default"}},[_vm._v(" "+_vm._s(device.deviceId)+" ")])],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"icons8-skyscrapers mr-2",attrs:{"color":"primary","size":"16"}}),_c('app-text',{staticClass:"grey--text",attrs:{"category":"text-default"}},[_vm._v(" "+_vm._s(device.deviceLocation)+" ")])],1)])],1)],1)}),0):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }