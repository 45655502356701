// * PHOTOUPLOAD STORE MODULE
const photoViewer_store = {
    namespaced: true,
    // * STATE
    state: {
        photoViewer: {
            title: 'Title',
            index: 0,
            photos: []
        },
    },
    // * MUTATIONS
    mutations: {
        MUT_photoViewer(state, data) { state.photoViewer = data },
    },
    // * GETTERS
    getters: {
        GET_photoViewer(state) { return state.photoViewer },
    },
    // * ACTIONS
    actions: {
        ACT_photoViewer({commit}, payload){ commit('MUT_photoViewer', payload) },
    }    

};
export default photoViewer_store;
