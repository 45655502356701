<!--
Delete Icon Dialog

Pop-up dialog to either cancel or confirm deleting a document.
Clicking the 'Delete' button will emit a message back to the calling component, which will handle the deletion.
Clicking the 'Cancel' button will simple close the dialog.
-->
<template>


    <!--Body-->
    <v-card>

        <!--Toolbar-->
        <v-toolbar class="px-2" color="primary">

            <!--Title-->
            <app-text category="text-medium" class="white--text">Change User's Status</app-text>

            <v-spacer/>

            <!--Close Icon-->
            <v-icon @click.native="closeDialog"
                    class="icons8-delete" color="white" size="32"/>

        </v-toolbar>

        <!--Content-->
        <div class="pa-4">

            <!--Message-->
            <app-text>
                You are about to change {{ userItem.userName }}'s status from {{ userItem.userStatus }} to {{ status }}
            </app-text>

            <!--Message-->
            <app-text category="text-default-bold" class="mt-4">Are you sure you want to do this?</app-text>

            <!--Action Buttons-->
            <div class="d-flex justify-space-between mt-4">

                <!--Cancel-->
                <app-btn @click.native="closeDialog"
                         color="lightgrey" label="Cancel"/>

                <app-btn @click.native="yesAction"
                         :color="status === 'APPROVED' ? 'success' : 'error'" :label="status"/>

            </div>

        </div>

    </v-card>

</template>

<script>
export default {

    name: "myOrganisationStatusConfirmationDialog",

    props: ['userItem', 'status'],

    methods: {

        yesAction() {
            const t = this

            t.$emit('emitStatusConfirmation', {id: t.$props.userItem.id, status: t.$props.status})
        },

        /**
         * Close Dialog
         *
         * Close the current dialog.
         */
        closeDialog() {
            const t = this

            t.$emit('emitCloseDialog')
        },
    }

}
</script>

<style scoped>
.deleteButton {
    border-radius: 50%;
    color: red;
    padding: 8px;
    transition: 0.5s;
}

.deleteButton:hover {
    background: red;
    color: white;
}
</style>
