<template>
	<div class="d-flex align-center justify-center flex-column">
		<apexchart
			v-if="series.length > 0"
			:options="chartOptions"
			:series="series"
			class=""
		></apexchart>
		<!-- <div
			v-if="threshold.class"
			:class="threshold.class.split(' ')[0]"
			class="mt-5 lighten-5 pa-2 rounded-lg"
		>
			<div class="body-2">{{ threshold.description }}</div>
		</div> -->
	</div>
</template>

<script>
	export default {
		props: {
			title: { type: Object, default: "Chart Title" },
			sensortype: { type: String, default: "" },
			chartdata: { type: String, default: "0" },
			loaded: { type: Boolean, default: false },
			thresholds: {
				type: Array,
				default: function() {
					return [];
				},
			},
			limits: {
				type: Array,
				default: function() {
					return [];
				},
			},
		},
		computed: {
			chartOptions: function() {
				return {
					chart: {
						type: "radialBar",
						offsetY: 0,
						parentHeightOffset: -100,
					},
					grid: {
						padding: {
							left: -30,
							right: -30,
							top: -30,
							bottom: -30,
						},
					},
					plotOptions: {
						radialBar: {
							startAngle: -90,
							endAngle: 90,
							track: {
								background: "#e7e7e7",
								strokeWidth: "97%",
								margin: 5, // margin is in pixels
							},
							dataLabels: {
								name: {
									show: true,
									fontSize: "16px",
									color: `${this.thresholdColor}`,
									// color: "#616161",
									offsetY: 35,
									formatter: (val) => {
										return val.toUpperCase();
									},
								},
								value: {
									offsetY: -5,
									fontSize: "40px",
									formatter: (val) => {
										return (
											((val / 100) * this.filteredLimit.max).toFixed()
										);
									},
									color: `${this.thresholdColor}`,
								},
							},
						},
					},
					colors: [	
						(val) => {
							return this.thresholdColor;
						},
					],
					labels: [this.thresholdText],
				};
			},
			filteredThresholds() {
				return this.thresholds.filter(
					(e) => e.sensorType === this.sensortype
				);
			},
			filteredLimit() {
				return this.limits.find(
					(e) => e.sensorType === this.sensortype
				);
			},
			thresholdColor() {
				let color = this.checkThreshold(
					this.filteredThresholds,
					this.chartdata
				)?.color;
				return color;
			},
			threshold() {
				let threshold = this.checkThreshold(
					this.filteredThresholds,
					this.chartdata
				);
				return threshold;
			},
			thresholdText() {
				let text = this.checkThreshold(
					this.filteredThresholds,
					this.chartdata
				)?.text;
				return text;
			},
		},
		data: () => ({
			series: [],
		}),
		methods: {
			async updateChart() {
				var chartData = {};
				chartData.name = "";
				chartData.data = this.chartdata;
				this.series = [((this.chartdata / this.filteredLimit.max) * 100)];
			},
			checkThreshold: function(thresholds, value) {
				let thresholdResult = thresholds.find((e) => {
					return value >= e.min && value < e.max;
				});
				if (thresholdResult) {
					return thresholdResult;
				} else {
					return {};
				}
			},
		},
		mounted() {
			this.updateChart();
		},
	};
</script>
