<!--
Device

Renders the form to add/edit/delete Device
-->
<template>
    <div>

        <!--Header-->
        <v-row class="pb-4" no-gutters>

            <!--Back Button-->
            <app-btn @click.native="switchToTable"
                     class="mr-4"
                     color="grey"
                     icon="icons8-back"/>

            <!--Title-->
            <app-text category="text-default">{{ form.deviceName ? form.deviceName : 'New Device' }}</app-text>

            <v-spacer/>

            <!--Action buttons-->
            <div>

                <!--Edit-->
                <edit-icon v-if="formModeDevice !== 'New'" @click.native="editDocument"/>

                <!--Delete-->
                <v-icon v-if="formModeDevice !== 'New'"
                        @click.native="deleteDocument(form.id)"
                        class="icons8-trash frc-icon"
                        color="error"
                        size="32"/>

            </div>

        </v-row>

        <!--Form-->
        <v-form>

            <!--Form Fields-->
            <v-row no-gutters>

                <!--Device Name -->
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                    <app-input input-type="textInput"
                               :class="$vuetify.breakpoint.width >= 600 ? 'pr-2' : ''"
                               :error="errors.deviceName"
                               :error-messages="errors.deviceNameErrorMessage"
                               :is-form-read-only="formReadOnly"
                               label="Device Name"
                               prepend-inner-icon="icons8-business-building"
                               v-model.trim="form.deviceName"/>
                </v-col>

                <!--Device Id -->
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                    <app-input input-type="textInput"
                               :class="$vuetify.breakpoint.width >= 600 ? 'pl-2' : 'mt-4'"
                               :error="errors.deviceId"
                               :error-messages="errors.deviceIdErrorMessage"
                               :is-form-read-only="formReadOnly"
                               label="Device Id"
                               prepend-inner-icon="icons8-business-building"
                               v-model.trim="form.deviceId"/>
                </v-col>

                <!--Device Type -->
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                    <app-input input-type="select"
                               class="rounded-lg mt-4"
                               :class="$vuetify.breakpoint.width >= 600 ? 'pr-2' : 'mt-4'"
                               :error="errors.deviceType"
                               :error-messages="errors.deviceTypeErrorMessage"
                               :is-form-read-only="formReadOnly"
                               :items="['Think Air CO2 Internal Node', 'Think Air PM External Node']"
                               label="Device Type"
                               prepend-inner-icon="icons8-business-building"
                               v-model.trim="form.deviceType"/>
                </v-col>

                <!--Device Location -->
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                    <app-input input-type="textInput"
                               class="mt-4"
                               :class="$vuetify.breakpoint.width >= 600 ? 'pl-2' : 'mt-4'"
                               :error="errors.deviceLocation"
                               :error-messages="errors.deviceLocationErrorMessage"
                               :is-form-read-only="formReadOnly"
                               label="Device Location"
                               prepend-inner-icon="icons8-business-building"
                               v-model.trim="form.deviceLocation"/>
                </v-col>

                <!-- NEW Device Site Name -->
                <v-col v-if="formModeDevice === 'New'" :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                    <app-input input-type="select"
                               class="rounded-lg mt-4"
                               :class="$vuetify.breakpoint.width >= 600 ? 'pr-2' : 'mt-4'"
                               :error="errors.siteName"
                               :error-messages="errors.siteNameErrorMessage"
                               :is-form-read-only="formReadOnly"
                               :items="sitesCollectionData"
                               item-text="siteName"
                               label="Site Name"
                               prepend-inner-icon="icons8-business-building"
                               v-model.trim="form.siteName"/>
                </v-col>

                <!--EXISTING Device Site Name -->
                <v-col v-if="formModeDevice !== 'New'" :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                    <app-input input-type="textInput"
                               class="mt-4"
                               :class="$vuetify.breakpoint.width >= 600 ? 'pr-2' : 'mt-4'"
                               disabled="disabled"
                               label="Site Name"
                               :is-form-read-only="formReadOnly"
                               prepend-inner-icon="icons8-business-building"
                               v-model.trim="form.siteName"/>
                </v-col>

                <!--Device Organisation Name -->
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                    <app-input input-type="textInput"
                               class="mt-4"
                               :class="$vuetify.breakpoint.width >= 600 ? 'pl-2' : 'mt-4'"
                               disabled="disabled"
                               label="Organisation Name"
                               :is-form-read-only="formReadOnly"
                               prepend-inner-icon="icons8-business-building"
                               v-model.trim="form.organisationName"/>
                </v-col>

                <!--Device Notification Emails -->
                <v-col :cols="12">
                    <app-input input-type="comboBox"
                               chips
                               class="mt-4 anotherclass"
                               deletable-chips
                               :error="errors.deviceNotificationEmail"
                               :error-messages="errors.deviceNotificationEmailErrorMessage"
                               :disabled="formReadOnly"
                               :is-form-read-only="formReadOnly"
                               :items="computedUserEmails"
                               item-text="userEmail"
                               label="Notification Emails when Threshold Limit Exceeded"
                               multiple
                               small-chips
                               solo
                               eager
                               v-model="form.deviceNotificationEmail"/>
                </v-col>

                <form-section-title class="my-4" title="Mapping Coordinates"/>

                <!--Device Latitude-->
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                    <app-input input-type="textInput"
                               :class="$vuetify.breakpoint.width >= 600 ? 'pr-2' : 'mt-4'"
                               :error="errors.deviceLatitude"
                               :error-messages="errors.deviceLatitudeErrorMessage"
                               label="Device Latitude"
                               :is-form-read-only="formReadOnly"
                               prepend-inner-icon="icons8-company"
                               v-model.number="latitudeDisplay"
                    />
                </v-col>

                <!--Device Longitude-->
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                    <app-input input-type="textInput"
                               :class="$vuetify.breakpoint.width >= 600 ? 'pl-2' : 'mt-4'"
                               :error="errors.deviceLongitude"
                               :error-messages="errors.deviceLongitudeErrorMessage"
                               label="Device Longitude"
                               :is-form-read-only="formReadOnly"
                               prepend-inner-icon="icons8-company"
                               v-model.number="longitudeDisplay"/>
                </v-col>

                <!-- <v-col :cols="12">
                 <v-checkbox class="d-flex align-start mt-4"
                             color="primary"
                             hide-details
                             :disabled="formReadOnly"
                             label="By selecting this option, your Device will be visible on the ThinkAir map"
                             v-model="form.hideOnThinkAirMap">
                 </v-checkbox>
                 <v-checkbox v-if="form.hideOnThinkAirMap" class="d-flex align-start mt-4"
                             color="primary"
                             hide-details
                             :disabled="formReadOnly"
                             label="By selecting this option, your Device’s marker will be visible, but kept anonymous"
                             v-model="form.anonymityOnThinkAirMap">
                 </v-checkbox>
             </v-col> -->

            </v-row>

            <!--Cancel and Save buttons-->
            <div class="d-flex mt-4">
                <app-btn v-if="!formReadOnly"
                         @click.native="switchToTable"
                         color="error"
                         icon="icons8-business-building"
                         label="Cancel"/>

                <v-spacer/>

                <app-btn v-if="!formReadOnly"
                         @click.native="validateForm"
                         color="success"
                         icon="icons8-business-building"
                         label="Create"/>
            </div>

        </v-form>

    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {

    name: "DeviceForm",

    props: ['deviceData', 'formMode', 'organisationId', 'organisationName'],

    components: {},

    data: () => ({
        collection: 'devices',
        collectionTitle: 'Devices',
        collectionItem: 'device',
        collectionItemTitle: 'Device',
        devicesCollectionData: [],
        sitesCollectionData: [],
        usersCollectionData: [],
        errors: {
            deviceName: false,
            deviceNameErrorMessage: '',
            deviceId: false,
            deviceIdErrorMessage: '',
            deviceType: false,
            deviceTypeErrorMessage: '',
            deviceLocation: false,
            deviceLocationErrorMessage: '',
            deviceNotificationEmail: false,
            deviceNotificationEmailErrorMessage: '',
            siteName: false,
            siteNameErrorMessage: '',
            // organisationName: false,
            // organisationNameErrorMessage: '',
        },
        form: {
            id: '',
            deviceName: '',
            deviceId: '',
            deviceType: '',
            deviceLocation: '',
            deviceNotificationEmail: [],
            organisationId: '',
            organisationName: '',
            siteId: '',
            siteName: '',
            hideOnThinkAirMap: false,
            deviceLatitude: '',
            deviceLongitude: '',
            anonymityOnThinkAirMap: false,
        },
        // formMode: '',
        formModeDevice: '',
        formReadOnly: false,
        tabs: 'device',
    }),

    computed: {
        latitudeDisplay: {
            get: function () {
                return parseFloat(this.form.deviceLatitude).toFixed(6);
            },
            set: function (newValue) {
                this.form.deviceLatitude = newValue;
            }
        },
        longitudeDisplay: {
            get: function () {
                return parseFloat(this.form.deviceLongitude).toFixed(6);
            },
            set: function (newValue) {
                this.form.deviceLongitude = newValue;
            }
        },
        ...mapGetters({
            GET_confirmBoxResult: 'confirmBox_store/GET_confirmBoxResult',
            GET_currentUser: 'GET_currentUser',
        }),

        computedUserEmails() {
            const t = this

            let users = t.usersCollectionData.filter(user => user.organisationId.includes(t.organisationId))
            users = users.map(email => email.userEmail)

            return users

        },

    },

    methods: {

        switchToTable(event) {
            const t = this

            t.$emit('switchComponent', 'deviceForm')
        },

        /**
         * Validate
         *
         * Validates the required fields.
         * If any of the fields are missing mark them in an errors object.
         * When there are no errors left, save the record.
         */

        validateForm() {
            const t = this
            const emailRegex = /.+@.+\..+/

            t.errors.deviceName = false
            t.errors.deviceNameErrorMessage = ''
            t.errors.deviceId = false
            t.errors.deviceIdErrorMessage = ''
            t.errors.deviceType = false
            t.errors.deviceTypeErrorMessage = ''
            t.errors.deviceLocation = false
            t.errors.deviceLocationErrorMessage = ''
            t.errors.siteName = false
            t.errors.siteNameErrorMessage = ''
            // t.errors.organisationName = false
            // t.errors.organisationNameErrorMessage = ''
            t.errors.deviceNotificationEmail = false
            t.errors.deviceNotificationEmailErrorMessage = ''

            // device Name
            if (!t.form.deviceName.trim()) {
                t.errors.deviceName = true
                t.errors.deviceNameErrorMessage = 'Device Name required'
            }
            // Device Id
            if (!t.form.deviceId.trim()) {
                t.errors.deviceId = true
                t.errors.deviceIdErrorMessage = 'Device Id required'
            }

            // Device Type
            if (!t.form.deviceType) {
                t.errors.deviceType = true
                t.errors.deviceTypeErrorMessage = 'Device Type required'
            }

            // Device Location
            if (!t.form.deviceLocation.trim()) {
                t.errors.deviceLocation = true
                t.errors.deviceLocationErrorMessage = 'Device Location required'
            }

            // // Device Site Name
            if (!t.form.siteName) {
                t.errors.siteName = true
                t.errors.siteNameErrorMessage = 'Site Name required'
            }

            // // Device Organisation Name
            // if (!t.form.organisationName) {
            //     t.errors.organisationName = true
            //     t.errors.organisationNameErrorMessage = 'Organisation Name required'
            // }

            // Check if the email addresses are in the correct format
            if (t.form.deviceNotificationEmail.length > 0) {

                for (let email of t.form.deviceNotificationEmail) {
                    if (!emailRegex.test(email)) {
                        t.errors.deviceNotificationEmail = true
                        t.errors.deviceNotificationEmailErrorMessage = 'You have entered an invalid email address'
                    }
                }
            }

            // Check if there any errors left
            if (!Object.values(t.errors).includes(true)) {
                this.saveDocument()
            }
        },

        /**
         * Save Document
         *
         * Save or update the document with the collection name and form details.
         *
         * @returns {Promise<void>}
         */
        async saveDocument() {

            const t = this
            let createDocumentResult

            let filteredSiteId = t.sitesCollectionData.filter(site => site.siteName == t.form.siteName)
            let siteId = filteredSiteId.map(site => site.id)

            t.form.siteId = siteId[0]

            // If the formMode is New, create a new document
            if (t.formModeDevice === 'New') {
                const docRef = t.$firebase.db.collection('devices').doc()

                t.form.id = docRef.id

                createDocumentResult = await t.MIX_createDocument(t.collection, t.form)
            }

            // If the formModeDevice is Edit, update the current document
            else if (t.formModeDevice === 'Edit') {
                createDocumentResult = await t.MIX_updateDocument(t.collection, t.form)
            }

            // If the document saved/updated successfully, set the form back to viewable state
            if (createDocumentResult.code === 1) {
                t.formModeDevice = 'View'
                t.formReadOnly = true
                // Switch back to table view
                t.switchToTable()
            }

            // Call for a confirmation alert
            t.renderConfirmationAlert(createDocumentResult)

        },

        /**
         * Edit Document
         *
         * Toggle the form between an editable, and a viewable state.
         */
        editDocument() {
            const t = this

            if (t.formReadOnly) {
                t.formReadOnly = false
                t.formModeDevice = 'Edit'

            } else {
                t.formReadOnly = true
                t.formModeDevice = 'View'
            }

        },

        /**
         * Delete Document
         *
         * Call for the confirmation box to delete a document.
         * The deletion is handled in this file's watch -> GET_confirmBoxResult handler function.
         */
        deleteDocument: function (id) {
            const t = this
            const width = t.$vuetify.breakpoint.width <= 425 ? '' : '400'

            t.MIX_confirmBox(
                {
                    show: true,
                    collection: t.collection,
                    id: id,
                    title: `Delete ${t.collectionItemTitle}`,
                    description: `Are you sure you want to delete this ${t.collectionItemTitle}: <br /><div class="mt-4"><strong>${t.form[t.collectionItem + 'Name']}</strong></div>`,
                    width,
                    trueButtonText: 'Yes',
                    trueButtonValue: 'YES',
                    falseButtonText: 'No',
                    falseButtonValue: 'NO',
                }
            )
        },

        /**
         * Render Confirmation Alert
         *
         * Take the response of creating a document and render:
         *  -> 'Success' alert if all is ok
         *  -> 'Error' alert if something when wrong
         */
        renderConfirmationAlert(document) {
            const t = this

            if (document.code === 1) {
                t.MIX_alert(1, `${t.collectionItemTitle} saved`, null, null)
            } else {
                t.MIX_alert(-1, `Error saving ${t.collectionItemTitle}`, null, null)
            }
        },

        /**
         * Get Sites Collection
         *
         * Clear the table data to avoid duplications.
         * Fetch all the data from the Sites collection.
         * Iterate over the collection and only push documents that aren't marked as deleted to the tableData array.
         *
         * @returns {Promise<void>}
         */
        async getSitesCollectionData() {
            const t = this

            if (t.organisationId) {
                const collection = t.$firebase.db.collection('sites').where('organisationId', '==', t.organisationId)

                collection.onSnapshot(snapShot => {

                    // Clear the sitesCollectionData data to avoid duplications
                    t.sitesCollectionData = []

                    snapShot.forEach(doc => {

                        const document = doc.data()
                        document.id = doc.id

                        // Only add documents that aren't marked as deleted
                        if (!document.hasOwnProperty('delete')) {
                            t.sitesCollectionData.push(document)
                        }
                    })
                })
            }
        },

        async getUsersCollectionData() {
            const t = this

            if (t.organisationId) {
                const collection = t.$firebase.db.collection('users')

                collection.onSnapshot(snapShot => {

                    // Clear the sitesCollectionData data to avoid duplications
                    t.usersCollectionData = []

                    snapShot.forEach(doc => {

                        const document = doc.data()
                        document.id = doc.id

                        // Only add documents that aren't marked as deleted
                        if (!document.hasOwnProperty('delete')) {
                            t.usersCollectionData.push(document)
                        }
                    })
                })
            }
        },

    },

    watch: {
        GET_confirmBoxResult: {
            handler: async function () {
                const t = this

                if (t.GET_confirmBoxResult.data.collection === 'devices') {
                    if (t.GET_confirmBoxResult.data.result === 'YES') {
                        const deleteDocumentResult = await t.MIX_deleteDocument(t.GET_confirmBoxResult.data.collection, t.GET_confirmBoxResult.data.id);

                        if (deleteDocumentResult.code === 1) {
                            t.MIX_alert(1, `Device Deleted`, deleteDocumentResult.data, null);
                            // Switch back to table view
                            t.switchToTable()
                        } else {
                            t.MIX_alert(-1, `Error Deleting Device`, null, null);
                        }
                    }
                }

            }, deep: true
        }
    },

    async mounted() {
        const t = this

        // Get and populate form data
        if (t.formMode !== 'New') {
            t.form = t.deviceData
            t.formReadOnly = true
            t.formModeDevice = t.formMode;
        } else {
            t.formModeDevice = t.formMode;
            t.form.organisationId = t.organisationId
            t.form.organisationName = t.organisationName
        }

        await t.getSitesCollectionData()
        await t.getUsersCollectionData()
    }

}
</script>

<style>
/* .v-select__selections {
  overflow: scroll;
  flex-wrap: nowrap;
}
.v-chip {
  overflow: initial;
} */
</style>
