<!--
User Profile

Render the user's profile and schedule data.
From here the user can adjust their details and also be advised on missing information.
-->
<template>
    <div style="width: 100%">

        <!--Header (Title | Instructional text)-->
        <div class="flex-column ma-4">

            <div class="d-flex">

                <!--Page title-->
                <page-title icon="icons8-person" page-title="My Profile"/>

                <v-spacer/>

                <!--Edit-->
                <edit-icon @click.native="editDocument"/>

            </div>

            <!--Instructional text-->
            <app-text>
                This is your profile.
                From here you can update your personal details.
            </app-text>

        </div>

        <v-divider class="mt-4 mx-4"/>

        <!--Form-->
        <v-row class="pa-4" no-gutters>

            <!--Profile Picture | User Details-->
            <v-row no-gutters style="width: 100%">

                <!--Profile picture-->
                <v-col
                    class="d-flex justify-center align-center pa-2 rounded-lg white profileImage-container"
                    :class="$vuetify.breakpoint.width >= 600 ? 'flex-grow-0 mr-4' : ''"
                    :cols="$vuetify.breakpoint.width < 600 && 12"
                    style="height: 272px; width: 272px">

                    <!--If an image is present, render it-->
                    <div v-if="form.profilePicFileURL && !tempImage"
                         class="d-flex flex-column align-center">

                        <!--Image-->
                        <v-img :src="form.profilePicFileURL"
                               class="rounded-lg" cover height="254" width="254"/>

                        <!--Upload button-->
                        <div v-if="formMode === 'Edit'"
                             style="position: absolute; z-index: 9; margin-top: 200px">

                            <photoupload class="mr-n4"
                                         allowedtypes="image/*"
                                         :docLink="{collection: 'users', documentId: form.id}"
                                         folder="users-profile-pictures"/>

                        </div>

                    </div>

                    <!--If a tempImage (upload preview) is present, render it-->
                    <div v-else-if="tempImage"
                         class="d-flex flex-column align-center">

                        <!--Image-->
                        <v-img :src="tempImage"
                               class="rounded" cover height="254" width="254"/>

                        <!--Upload button-->
                        <div style="position: absolute; z-index: 9; margin-top: 200px">

                            <photoupload class="mr-n4"
                                         allowedtypes="image/*"
                                         :docLink="{collection: 'users', documentId: form.id}"
                                         folder="users-profile-pictures"/>

                        </div>

                    </div>

                    <!--If neither an image or tempImage is present, render an icon-->
                    <div v-else class="d-flex flex-column align-center" style="width: 254px">

                        <!--Image-->
                        <v-icon class="icons8-customer" size="254"/>

                        <!--Upload button-->
                        <div v-if="formMode === 'New' || formMode === 'Edit'"
                             style="position: absolute; z-index: 9; margin-top: 200px">

                            <photoupload class="mr-n4"
                                         style="width: 100%"
                                         allowedtypes="image/*"
                                         :docLink="{collection: 'users', documentId: form.id}"
                                         folder="users-profile-pictures"/>

                        </div>

                    </div>

                </v-col>

                <!-- User details -->
                <v-col :class="$vuetify.breakpoint.width >= 600 && 'flex-grow-1'"
                       :cols="$vuetify.breakpoint.width < 600 && 12">

                    <!--Name-->
                    <app-input input-type="textInput"
                               :class="$vuetify.breakpoint.width < 600 && 'mt-4'"
                               :error="errors.userName"
                               :error-messages="errors.userNameErrorMessage"
                               :is-form-read-only="formReadOnly"
                               label="Full Name"
                               v-model.trim="form.userName"/>

                    <!--Job Title-->
                    <app-input input-type="textInput"
                               class="mt-4"
                               :error="errors.userJobTitle"
                               :error-messages="errors.userJobTitleErrorMessage"
                               :is-form-read-only="formReadOnly"
                               label="Job Title"
                               v-model.trim="form.userJobTitle"/>

                    <!--Email-->
                    <app-input input-type="textInput"
                               class="mt-4"
                               :error="errors.userEmail"
                               :error-messages="errors.userEmailErrorMessage"
                               :is-form-read-only="formReadOnly"
                               label="Email"
                               v-model.trim="form.userEmail"/>

                    <!--Telephone-->
                    <app-input input-type="textInput"
                               class="mt-4"
                               :error="errors.userTelephone"
                               :error-messages="errors.userTelephoneErrorMessage"
                               :is-form-read-only="formReadOnly"
                               label="Telephone"
                               type="number"
                               v-model="form.userTelephone"/>

                </v-col>

            </v-row>

            <!--Configuration-->
            <v-row v-if="['SA'].includes(GET_currentUser.userLevel)" no-gutters>

                <!-- Configuration (User Type | User Role | User Status) -->
                <form-section-title title="Configuration"/>

                <!--User type-->
                <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 4"
                       :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'">
                    <app-input input-type="select"
                               :is-form-read-only="formReadOnly"
                               :items="userTypesOptionsData"
                               label="User type"
                               v-model="form.userType">
                    </app-input>
                </v-col>

                <!--User role-->
                <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 4"
                       :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 px-2'">
                    <app-input input-type="select"
                               :is-form-read-only="formReadOnly"
                               :items="computedUserRoles"
                               label="User Role"
                               v-model="form.userRole">
                    </app-input>
                </v-col>

                <!--User status-->
                <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 4"
                       :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'">
                    <app-input input-type="select"
                               :is-form-read-only="formReadOnly"
                               :items="userStatusOptionsData"
                               label="User Status"
                               v-model="form.userStatus">
                    </app-input>
                </v-col>
            </v-row>

            <!--Save-->
            <v-col cols="12" class="d-flex justify-end my-4">
                <app-btn v-if="formMode === 'Edit' || formMode === 'New'"
                         @click.native="validateForm"
                         color="success"
                         icon="icons8-save"
                         label="Save"/>
            </v-col>

        </v-row>

    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "userProfile",

    data: () => ({

        errors: {
            userName: false,
            userNameErrorMessage: '',
            userJobTitle: false,
            userJobTitleErrorMessage: '',
            userEmail: false,
            userEmailErrorMessage: '',
            userTelephone: false,
            userTelephoneErrorMessage: '',
        },


        formMode: 'View',
        formReadOnly: true,
        formBackground: 'grey lighten-3',
        form: {
            id: '',
            profilePicFileURL: null,
            userName: '',
            userJobTitle: '',
            userEmail: '',
            userTelephone: '',
            userLevel: '',

            createdUserData: {},
            createdDateTime: '',
            modifiedUserData: {},
            modifiedDateTime: '',
        },

        // Profile picture
        types: "image/*",
        storagePathProfilePic: "users-profile-pictures",
        photoUploadResult: {},
        tempImage: '',
    }),

    computed: {
        ...mapGetters({
            GET_photoUploadResult: 'photoUpload_store/GET_photoUploadResult',
        }),

        /**
         * Computed User Roles
         *
         * Return the correct user roles for the specified user type.
         * @returns {Array} user roles as strings
         */
        computedUserRoles() {
            const t = this
            let roles = []
            const type = t.form.userType

            if (type === 'Staff') {
                roles = ['User', 'Admin']
            } else if (type === 'Client') {
                roles = ['User', 'Admin']
                // t.form.userRole = 'User'
            }

            return roles
        },

    },

    methods: {

        /**
         * Get User Collection Data
         *
         * Fetch user collection data for the current user and sync it to the form.
         *
         * @returns {Promise<void>}
         */
        async getUserCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection('users').doc(t.GET_currentUser.id)
            const doc = await collection.get()

            if (doc.exists) {
                t.userCollectionData = doc.data()
                t.form = doc.data()
            } else {
                // console.log('UserProfile error fetching user data')
            }

        },

        /**
         * Edit Document
         *
         * Set the form to an editable state.
         */
        editDocument() {
            const t = this

            if (t.formMode === 'View') {
                t.formMode = 'Edit'
                t.formReadOnly = false
                t.formBackground = "white"
            } else if (t.formMode === 'Edit') {
                t.cancelDocument()
            }

        },

        /**
         * Cancel Document
         *
         * Set the form to a viewable state and refresh the user data.
         *
         * @returns {Promise<void>}
         */
        async cancelDocument() {
            const t = this

            t.formMode = "View"
            t.formReadOnly = true
            t.formBackground = "grey lighten-3"
            t.tempImage = '';

            // Refresh user data
            await t.getUserCollectionData()
        },

        /**
         * Validate
         *
         * Validates the required fields for presence only.
         * If any of the fields are missing mark them in an errors object.
         * When there are no errors left, save the record.
         */
        validateForm() {
            const t = this
            const emailRegex = /.+@.+\..+/

            t.errors.userName = false
            t.errors.userNameErrorMessage = ''
            t.errors.userJobTitle = false
            t.errors.userJobTitleErrorMessage = ''
            t.errors.userEmail = false
            t.errors.userEmailErrorMessage = ''
            t.errors.userTelephone = false
            t.errors.userTelephoneErrorMessage = ''

            // Name
            if (!t.form.userName.trim()) {
                t.errors.userName = true
                t.errors.userNameErrorMessage = 'Name required'
            }
            // Must be between 2 and 60 characters
            else if (t.form.userName.trim().length < 2 || t.form.userName.trim().length > 60) {
                t.errors.userName = true
                t.errors.userNameErrorMessage = 'Name must be between 2 and 60 characters'
            }

            // Job Title
            if (!t.form.userJobTitle.trim()) {
                t.errors.userJobTitle = true
                t.errors.userJobTitleErrorMessage = 'Job Title required'
            }

            // Email
            if (!t.form.userEmail.trim()) {
                t.errors.userEmail = true
                t.errors.userEmailErrorMessage = 'Email required'
            }
            // Must be a (simple) valid email address
            else if (!emailRegex.test(t.form.userEmail)) {
                t.errors.userEmail = true
                t.errors.userEmailErrorMessage = 'Email not valid'
            }

            // Check if there any errors left
            if (!Object.values(t.errors).includes(true)) {
                t.setUserLevel()
                t.saveDocument()
            }
        },

        /**
         * Set User Level
         *
         * Configure the user's user level from their user type and user role.
         */
        setUserLevel() {
            const t = this

            t.form.userLevel = t.form.userType[0] + t.form.userRole[0]
        },

        /**
         * Save Document
         *
         * Update the user document.
         * Set the form to a viewable state.
         * Call to upload the profile picture if one has been selected.
         * Refresh the user data.
         *
         * @returns {Promise<void>}
         */
        async saveDocument() {
            const t = this

            const updateDocumentResult = await this.MIX_updateDocument('users', t.form)

            if (updateDocumentResult.code === 1) {

                t.formBackground = "grey lighten-3"
                t.formMode = "View"
                t.formReadOnly = true
                t.tempImage = ''

                // Add uploaded profile image
                await t.uploadProfileImage()

                // Refresh user data
                await t.getUserCollectionData()

                t.MIX_go('/')

            }

            // Call for a confirmation alert
            t.renderConfirmationAlert(updateDocumentResult, 'Profile successfully updated', 'Error updating profile')
        },

        /**
         * Upload Profile Image
         *
         * Update the user's document with a profile image path  (collection | user id | image path).
         *
         * @returns {Promise<void>}
         */
        async uploadProfileImage() {
            const t = this

            if (t.photoUploadResult !== {}) {

                // Save to the document with: collection | user id | image path
                const updatePhotosResult = await this.MIX_updateDocumentFieldsById(
                    'users', t.photoUploadResult.docLink, {profilePicFileURL: t.photoUploadResult.fileURL})

                // Call for a confirmation alert
                t.renderConfirmationAlert(updatePhotosResult, 'Photo successfully updated', 'Error updating photo')

            }
        },

        /**
         * Render Confirmation Alert
         *
         * Take the result of a document db change and render a confirmation box to the user.
         */
        renderConfirmationAlert(document, successMessage, failureMessage) {
            const t = this

            if (document.code === 1) {
                t.MIX_alert(1, successMessage, null, null)
            } else {
                t.MIX_alert(-1, failureMessage, null, document.error)
            }

        },

    },

    watch: {

        /**
         * Photo Upload Result
         *
         * Watch for the result of a profile picture upload and add its storage path to the photoUploadResult variable.
         */
        GET_photoUploadResult: {
            handler: async function () {
                const t = this

                t.photoUploadResult = t.GET_photoUploadResult
                t.tempImage = t.photoUploadResult.fileURL

            }, deep: true
        },

    },

    async mounted() {
        const t = this

        // Fetch required collection data
        await t.getUserCollectionData()

    }
}
</script>
