<!--
Devices

Renders the Devices page which displays a table containing the Devices data.

The page also contains an Device form where the authorised User can add/edit/delete Devices.
-->
<template>
    <div class="ma-0 px-4">

    <!-- <v-divider class="ma-4"/> -->

        <v-row class="my-4" no-gutters>

            <!--Search-->
            <v-col v-if="component === 'myOrganisationDevicesTable'" :cols="$vuetify.breakpoint.width <= 600 ? 12 : 6"
                class="pa-0 mb-4">

                <app-input input-type="textInput"
                           :append-icon="'icons8-search'"
                           clearable
                           :label="$t('filters.search')"
                           placeholder="Search by device name, id, type, location or site name"
                           v-model="search"/>

            </v-col>

            <!--New Device Button-->
            <!-- <v-col v-if="component === 'myOrganisationDevicesTable'" class="d-flex justify-end align-end pa-0 mb-4">

                <app-btn @click.native="component = 'myOrganisationDevicesForm'; newFormButtonClicked = true"
                         :block="$vuetify.breakpoint.width <= 600"
                         color="primary"
                         icon="icons8-business-building"
                         label="New Device"/>

            </v-col> -->

            <!-- Switch component between myOrganisationDevicesTable and myOrganisationDevicesForm-->
            <component :is="component" v-on:switchComponent="switchComponent" v-bind="currentProperties"></component>

        </v-row>

    </div>
</template>

<script>

import myOrganisationDevicesForm from '../myOrganisationDevices/myOrganisationDevicesForm.vue'
import myOrganisationDevicesTable from '../myOrganisationDevices/myOrganisationDevicesTables.vue'


export default {

    components: {myOrganisationDevicesForm, myOrganisationDevicesTable},
    
    name: "myOrganisationDevices",

    props: ['organisationId', 'organisationName', 'doesFormStartReadOnly' ],

    data: () => ({
        title: 'Devices',
        collection: 'devices',
        collectionItem: 'device',
        collectionTitle: 'Devices',
        collectionItemTitle: 'Device',
        component: 'myOrganisationDevicesTable',
        deviceData:[],
        // formMode: '',
        newFormButtonClicked: false,
        search: '',
        tableData: [],
    }),

    computed: {

        currentProperties() {
            const t = this

            if (t.component === 'myOrganisationDevicesTable') {
                return { tableData: t.tableData, organisationId: t.organisationId, search:t.search }
            }
            if (t.component === 'myOrganisationDevicesForm' && t.newFormButtonClicked) {
                // Reset the value to false
                t.newFormButtonClicked = false
                return { deviceData: t.deviceData, formMode: 'New', organisationId: t.organisationId, organisationName: t.organisationName, doesFormStartReadOnly: t.doesFormStartReadOnly  }
            } else if (t.component === 'myOrganisationDevicesForm') {
                return { deviceData: t.deviceData, formMode: 'Edit', organisationId: t.organisationId, organisationName: t.organisationName, doesFormStartReadOnly: t.doesFormStartReadOnly }
            }

        },

    },

    methods: {
        /**
         * Switch component to show either Devices table or Device form 
         *
         */

        switchComponent(switchFrom, deviceData) {
            const t = this
            
            if(switchFrom === 'myOrganisationDevicesForm') {
                t.component = 'myOrganisationDevicesTable'
            } else if(switchFrom === 'myOrganisationDevicesTable') {
                t.component = 'myOrganisationDevicesForm'
                t.deviceData = deviceData
            }
        },

        /**
         * Get Devices Collection
         *
         * Clear the table data to avoid duplications.
         * Fetch all the data from the Devices collection.
         * Iterate over the collection and only push documents that aren't marked as deleted to the tableData array.
         *
         * @returns {Promise<void>}
         */
        async getDevicesCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection('devices')
            collection.onSnapshot(snapShot => {

                // Clear the table data to avoid duplications
                t.tableData = []

                snapShot.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.tableData.push(document)
                    }
                })
            })
        }
    },

    async mounted() {
        const t = this

        await t.getDevicesCollectionData()
    }

}
</script>
