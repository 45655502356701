<!--
Members

Renders the Members page which displays a table containing the Members data.

The page also contains a Member form where the authorised User can add/edit/delete Members.
-->
<template>
    <div class="ma-0 px-4 ">

        <!--Searches-->
        <v-row class="mt-4" no-gutters>

            <!--Search-->
            <v-col :cols="$vuetify.breakpoint.width <= 600 ? 12 : 6"
                   class="pa-0 mb-4">
                <app-input input-type="textInput"
                           clearable
                           :label="$t('filters.search')"
                           :append-icon="'icons8-search'"
                           placeholder="Search by name, telephone, email or status"
                           v-model="search"/>
            </v-col>

        </v-row>

        <!--Table-->
        <v-data-table v-if="$vuetify.breakpoint.width >= 600"
                      class="rounded-lg"
                      :headers="computedHeaders"
                      :items="filteredTableData"
                      :search="search"
                      sort-by="userName">

            <!-- Member Name-->
            <template v-slot:item.userName="{ item }">
                <app-text category="text-small">{{ item.userName }}</app-text>
            </template>

            <!-- Member Telephone -->
            <template v-slot:item.userTelephone="{ item }">
                <app-text category="text-small">{{ item.userTelephone }}</app-text>
            </template>

            <!--Member Email-->
            <template v-slot:item.userEmail="{ item }">
                <app-text category="text-small">{{ item.userEmail }}</app-text>
            </template>

            <template v-slot:item.userStatus="{ item }">
                <v-icon v-if="item.userStatus === 'APPROVED'"
                        class="icons8-checkmark-yes" color="success" size="32"/>
                <v-icon v-if="item.userStatus === 'ARCHIVED'"
                        class="icons8-trash" color="grey" size="32"/>
                <v-icon v-if="item.userStatus === 'PENDING'"
                        class="icons8-high-importance" color="orange" size="32"/>
                <v-icon v-if="item.userStatus === 'REJECTED'"
                        class="icons8-cancel" color="error" size="32"/>
                <v-icon v-if="item.userStatus === 'SUSPENDED'"
                        class="icons8-private" color="error" size="32"/>
            </template>

            <!--Action-->
            <template v-slot:item.actions="{ item }">
                <div class="d-flex align-center" style="height: 100%">
                    <v-menu bottom left>
                        <template v-slot:activator="{ on, attrs }">

                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item v-for="(item1, index) in actionsList" :key="index"
                                         @click="updateStatus(item, item1.action)">
                                <v-list-item-icon>
                                    <v-icon v-text="item1.icon" :color="item1.color" size="32"></v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>{{ item1.action }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>

        </v-data-table>

        <!--Mobile Cards-->
        <div v-if="$vuetify.breakpoint.width < 600">

            <v-card v-for="member in filteredTableData" :key="member.id"
                    class="rounded-lg mb-4 pa-4"
                    flat>

                <!-- @click="openItem(member.id, 'View', false)" -->

                <div class="d-flex align-center">

                    <!--Name-->
                    <app-text category="text-default-bold" class="mb-2">
                        {{ member.userName }}
                    </app-text>

                    <app-text v-if="member.userRole === 'Admin'"
                              category="text-default" class="mb-2 ml-2 grey--text">
                        ({{ member.userRole }})
                    </app-text>

                </div>

                <!--Contact details-->
                <div class="d-flex">

                    <!--Contact email-->
                    <div class="d-flex align-center">
                        <v-icon class="icons8-email mr-2" color="primary" size="16"/>
                        <app-text category="text-default" class="grey--text">
                            {{ member.userEmail }}
                        </app-text>
                    </div>

                </div>

            </v-card>

        </div>

        <!--Status Change Confirmation Dialog-->
        <v-dialog max-width="512"
                  transition="dialog-bottom-transition"
                  v-model="confirmationDialog">

            <my-organisation-status-confirmation-dialog :userItem="userToBeModified && userToBeModified"
                                                        :status="userStatusToBeModified && userStatusToBeModified"
                                                        v-on:emitCloseDialog="emittedCloseDialog"
                                                        v-on:emitStatusConfirmation="emittedStatusConfirmation"/>
        </v-dialog>

    </div>
</template>

<script>

import myOrganisationDialog from './myOrganisationStatusConfirmationDialog/myOrganisationStatusConfirmationDialog.vue'
import MyOrganisationStatusConfirmationDialog
    from "@/views/organisations/myOrganisation/myOrganisationMembers/myOrganisationStatusConfirmationDialog/myOrganisationStatusConfirmationDialog";

export default {

    name: "myOrganisationMembers",

    components: {MyOrganisationStatusConfirmationDialog, myOrganisationDialog},

    props: ['organisationId', 'organisationName'],

    data: () => ({
        confirmationDialog: false,
        userToBeModified: {},
        userStatusToBeModified: '',
        title: 'Members',
        collection: 'users',
        collectionItem: 'user',
        collectionTitle: 'Users',
        collectionItemTitle: 'user',
        actionsList: [{action: 'APPROVED', icon: 'icons8-checkmark-yes', color: 'success'},
            {action: 'PENDING', icon: 'icons8-high-importance', color: 'orange'},
            {action: 'REJECTED', icon: 'icons8-cancel', color: 'error'},
            {action: 'SUSPENDED', icon: 'icons8-private', color: 'error'},
            {action: 'ARCHIVED', icon: 'icons8-xbox-a', color: 'grey'}
        ],
        headers: [
            {text: 'Status', value: 'userStatus', align: 'left', sortable: false},
            {text: 'Name', value: 'userName', align: 'left', sortable: false},
            {text: 'Telephone', value: 'userTelephone', align: 'left', sortable: false},
            {text: 'Email', value: 'userEmail', align: 'left', sortable: false},
            {text: 'Role', value: 'userRole', align: 'left', sortable: false},
            {text: 'Actions', value: 'actions', sortable: false, width: '80px', align: 'left'},
            // {text: '', value: 'actions', align: 'right', sortable: false, width: '134px'},
        ],
        search: '',
        tableData: [],
    }),

    computed: {

        /**
         * Computed Headers
         *
         * Returns a filtered headers array which is only those not marked as hidden.
         */
        computedHeaders() {
            const t = this

            return t.headers.filter(header => !header.hidden)
        },


        /**
         * Filtered Table Data
         *
         * Take the tableData and return an array of filtered Members that are a part of the Organisation.
         *
         * @returns array - filtered users
         */
        filteredTableData() {
            const t = this
            let tableData = t.tableData

            // Get members of the Organisation
            if (t.organisationId) {
                tableData = tableData.filter(member =>
                    member.organisationId === t.organisationId || member.organisationId?.includes(t.organisationId))
            } else {
                tableData = []
            }

            return tableData
        },

    },

    methods: {

        emittedCloseDialog() {
            const t = this

            t.confirmationDialog = false
        },

        async emittedStatusConfirmation(payload) {
            const t = this

            await t.MIX_updateDocumentFieldsById('users', payload.id, {userStatus: payload.status})

            t.confirmationDialog = false
        },

        /**
         * Get Users Collection (Members)
         *
         * Clear the table data to avoid duplications.
         * Fetch all the data from the Users collection.
         * Iterate over the collection and only push documents that aren't marked as deleted to the tableData array.
         *
         * @returns {Promise<void>}
         */
        async getUsersCollectionData() {
            const t = this
            let collectionData = []

            await t.$firebase.db.collection('users')
                .onSnapshot(snapShot => {

                    // Clear the table data to avoid duplications
                    collectionData = []

                    snapShot.forEach(doc => {

                        const document = doc.data()
                        document.id = doc.id

                        // Only add documents that aren't marked as deleted
                        if (!document.hasOwnProperty('delete')) collectionData.push(document)
                    })
                    t.tableData = collectionData
                })
        },

        updateStatus(item, status) {
            const t = this

            t.userToBeModified = item
            t.userStatusToBeModified = status

            t.confirmationDialog = true
        },

    },

    async mounted() {
        const t = this

        // Get collection data
        await t.getUsersCollectionData()
    }

}
</script>
