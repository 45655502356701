<!--
HOME

Renders the home screen which contains the following:
	- Named greeting
	- List of Devices with link to chart/{deviceId} page
	- (Admin only) Users Awaiting Approval

-->
<template>
    <div class="pa-4" style="width: 100%">

        <!--Welcome User-->
        <app-text category="text-large" class="primary--text" style="width: 100%">
            Welcome <span class="accent--text">{{ GET_currentUser.userName.split(" ")[0] }}</span>
        </app-text>

        <!--Page Content-->
        <div class="home-container mt-4">

            <!--Left side - List Devices-->
            <v-card class="d-flex flex-column rounded-lg pa-4" flat>

                <!--Icon | Title-->
                <div class="d-flex">

                    <!--Icon-->
                    <v-icon class="icons8-sensor mr-4" color="accent" size="48"/>

                    <!--Title-->
                    <app-text category="text-medium-bold" class="primary--text">
                        Devices in your account
                    </app-text>

                </div>

                <!--Select Organisation if User is a member of more than 1 Organisation -->
                <v-select v-if="GET_currentUser.organisationId.length > 1"
                          @change="handleOrganisationChange()"
                          class="rounded-lg mt-4"
                          flat
                          hide-details
                          :items="computedUsersOrganisations"
                          label="Organisation"
                          outlined
                          v-model="organisationSelector"/>

                <!--Devices Card-->
                <div v-for="device in computedUsersDevices" :key="device.id">
                    <v-card class="rounded-lg" flat>

                        <v-divider class="my-4"/>

                        <v-row no-gutters>

                            <v-col>

                                <!--Site Name-->
                                <app-text category="text-default-bold" class="mb-2">{{ device.siteName }}</app-text>

                                <!--Device Name-->
                                <app-text class="mb-2">{{ device.deviceName }}</app-text>

                                <!--Organisation Name-->
                                <app-text>{{ device.organisationName }}</app-text>

                            </v-col>

                            <app-btn @click.native="MIX_go('/charts/'); setUpChart(device)"
                                     color="primary"
                                     label="Open"/>

                        </v-row>

                    </v-card>
                </div>

            </v-card>

            <!--Right side (Awaiting approval)-->
            <div v-if="GET_currentUser.userLevel === 'SA'">

                <!--Users awaiting approval-->
                <awaiting-approval-home-page-card :usersAwaitingApproval="usersAwaitingApproval"
                                                  :usersAwaitingApprovalMessage="usersAwaitingApprovalMessage"/>

            </div>

        </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex";
import AwaitingApprovalHomePageCard from "@/views/home/awaitingApprovalHomePageCard/awaitingApprovalHomePageCard";

export default {

    name: "Home",

    components: {
        AwaitingApprovalHomePageCard
    },

    data: () => ({
        isPhotoUploadDisabled: false,
        devicesCollectionData: [],
        deviceOrg: {},
        organisationsCollectionData: [],
        organisationSelector: '',
        organisationSelected: '',
        usersCollectionData: [],
    }),

    computed: {
        ...mapGetters({
            GET_currentUser: "GET_currentUser",
        }),

        /**
         * Computed User's Device
         *
         * Only show devices for Organisations that the user is a member of
         */
        computedUsersDevices() {
            const t = this
            let devices

            // If User is a part of multiple orgs, display devices from selected option.
            if (t.GET_currentUser?.organisationId.length > 1) {
                devices = t.devicesCollectionData.filter(device => device.organisationId === t.organisationSelected)
            } else {
                devices = t.devicesCollectionData.filter(device => device.organisationId === t.GET_currentUser.organisationId[0])
            }

            return devices
        },

        /**
         * Computed User's Organisations
         *
         * Only show Organisations that the user is a member of
         */
        computedUsersOrganisations() {
            const t = this
            let orgArray = []

            for (let i = 0; i < t.devicesCollectionData.length; i++) {
                const key = t.devicesCollectionData[i].organisationName
                t.deviceOrg[key] = t.devicesCollectionData[i].organisationId
            }

            for (const property in t.deviceOrg) {
                if (t.GET_currentUser.organisationId.includes(t.deviceOrg[property])) orgArray.push(property)
            }

            return orgArray
        },

        /**
         * Users Awaiting Approval Message
         *
         * Take the number of users awaiting approval and return a grammatically correct string to render.
         *
         * @returns message - message to render to screen
         */
        usersAwaitingApprovalMessage() {
            const t = this
            let message

            if (t.usersAwaitingApproval.length === 1) {
                message = `You have ${t.usersAwaitingApproval.length} account awaiting approval`
            } else {
                message = `You have ${t.usersAwaitingApproval.length} accounts awaiting approval`
            }

            return message
        },

        /**
         * Users Awaiting Approval
         *
         * Filter all users and return an array containing just those with a 'PENDING' status, awaiting approval.
         *
         * @returns array - array containing users with a 'PENDING' status
         */
        usersAwaitingApproval() {
            const t = this

            return t.usersCollectionData.filter(user => user.userStatus.toUpperCase() === 'PENDING')
        }
    },

    methods: {

        /**
         * Get Users Collection Data
         *
         * Fetch all the data from the Users collection.
         * Iterate over the collection and only push documents that aren't marked as deleted.
         *
         * @returns {Promise<void>}
         */
        async getUsersCollectionData() {
            const t = this
            let collectionData = []

            let collection = await t.$firebase.db.collection('users')

            // If user is not admin ('SA'), only get the current user data
            if (!['SA'].includes(t.GET_currentUser.userLevel)) {
                collection = collection.where("id", "==", t.GET_currentUser.id)
            }

            collection.get().then(user => {

                // Reset data to avoid duplications
                collectionData = []

                user.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

                    if (t.GET_currentUser.id === document.id) {

                        // Set usual site so the Complete Profile card doesn't hang
                        if (document.usualSite) {
                            t.GET_currentUser.usualSite = document.usualSite
                        }

                        // Update the user
                        t.$store.commit('SET_currentUser', document)
                    }

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) collectionData.push(document)

                })
                t.userCollectionData = collectionData
            })
        },

        async getDevicesCollectionData() {
            const t = this
            let collectionData = []

            await t.$firebase.db.collection('devices')
                .onSnapshot(snapShot => {

                    // Clear the collection data to avoid duplications
                    collectionData = []

                    snapShot.forEach(doc => {

                        const document = doc.data()
                        document.id = doc.id

                        // Only add documents that aren't marked as deleted
                        if (!document.hasOwnProperty('delete')) collectionData.push(document)
                    })
                    t.devicesCollectionData = collectionData
                })
        },

        async getOrganisationsCollectionData() {
            const t = this
            let collectionData = []

            const collection = t.$firebase.db.collection('organisations')
            collection.get().then(org => {

                // Clear the collection data to avoid duplications
                collectionData = []

                org.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) collectionData.push(document)
                })
                t.organisationsCollectionData = collectionData
            })
        },

        handleOrganisationChange() {
            const t = this

            for (const property in t.deviceOrg) {
                if (property === t.organisationSelector) {
                    t.organisationSelected = t.deviceOrg[property]
                }
            }

        },

        setUpChart(device) {
            this.$store.commit("SET_chartDevice", device)
        },

    },

    async mounted() {
        const t = this

        // Get collection data
        await t.getUsersCollectionData()
        await t.getDevicesCollectionData()
        await t.getOrganisationsCollectionData()

    }
}
</script>

<style>
.home-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    grid-gap: 16px;
}

.expansion-panel-content-container > div {
    padding: 16px;
}
</style>
