var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticStyle:{"height":"100%"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-4",attrs:{"cols":_vm.GET_panelLayout.leftPanel}},[_c('page-title',{attrs:{"icon":"icons8-business-building","pageTitle":"Organisations"}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-4",class:_vm.$vuetify.breakpoint.width < 600 ? '' : 'pr-2',attrs:{"cols":_vm.$vuetify.breakpoint.width < 600 ? 12 : 6}},[_c('app-input',{attrs:{"input-type":"textInput","clearable":true,"label":_vm.$t('filters.search'),"append-icon":'icons8-search'},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('div',{staticClass:"d-flex justify-end"},[_c('app-btn',{attrs:{"block":_vm.$vuetify.breakpoint.width <= 600,"color":"primary","icon":"icons8-business-building","label":"New"},nativeOn:{"click":function($event){return _vm.openItem('', 'New', false)}}})],1),(_vm.$vuetify.breakpoint.width >= 600)?_c('v-data-table',{staticClass:"rounded-lg mt-4",attrs:{"headers":_vm.computedHeaders,"items":_vm.filteredTableData,"search":_vm.search,"sort-by":"organisationName"},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.id))])]}},{key:"item.organisationName",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.organisationName))])]}},{key:"item.organisationContactName",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.organisationContactName))])]}},{key:"item.organisationTelephone",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.organisationTelephone))])]}},{key:"item.organisationEmail",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.organisationEmail))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-end",staticStyle:{"height":"100%"}},[_c('v-btn',{staticClass:"white text--red",attrs:{"depressed":""},on:{"click":function($event){return _vm.openItem(item.id, 'View', false)}}},[_c('v-icon',{staticClass:"icons8-forward",attrs:{"color":"primary"}})],1)],1)]}}],null,false,1669544326)}):_vm._e(),(_vm.$vuetify.breakpoint.width < 600)?_c('div',[(!_vm.filteredTableData.length)?_c('app-text',{staticClass:"d-flex justify-center text-center mt-4"},[_vm._v(" There are no Organisations to show ")]):_vm._e(),_vm._l((_vm.filteredTableData),function(organisation){return _c('v-card',{key:organisation.id,staticClass:"rounded-lg mt-4 pa-4",attrs:{"flat":""},on:{"click":function($event){return _vm.openItem(organisation.id, 'View', false)}}},[_c('app-text',{staticClass:"mb-2",attrs:{"category":"text-default-bold"}},[_vm._v(" "+_vm._s(organisation.organisationName)+" ")]),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"icons8-person mr-2",attrs:{"color":"primary","size":"16"}}),_c('app-text',{staticClass:"grey--text mr-4",attrs:{"category":"text-default"}},[_vm._v(" "+_vm._s(organisation.organisationContactName)+" ")])],1),_c('div',{staticClass:"d-flex align-center mt-2"},[_c('v-icon',{staticClass:"icons8-phone mr-2",attrs:{"color":"primary","size":"16"}}),_c('app-text',{staticClass:"grey--text",attrs:{"category":"text-default"}},[_vm._v(" "+_vm._s(organisation.organisationTelephone)+" ")])],1)])],1)})],2):_vm._e()],1),_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":"animate__animated animate__fadeIn animate__faster","leave-active-class":"animate__animated animate__fadeOut animate__faster","mode":"out-in"}},[_c('rightpanel')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }