<template>
    <v-row class="fill-height" no-gutters>
        <!-- // * LEFT PANEL -->
        <v-col :cols="GET_panelLayout.leftPanel" >
            <!-- // * LOOKUP TABLES FORM -->
            <v-row class="ma-0 px-3" no-gutters>
                <v-col class="my-2 pa-1" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <h3 class="grey--text text--darken-1">LOOKUP TABLES</h3>
                </v-col>
                <v-col class="my-2 pa-1" cols="12" xs="12" sm="2" md="2" lg="2" xl="2">
                    <v-text-field label="Table Name" v-model="formLT.lookupTableName" filled hide-details background-color="white" dense></v-text-field>
                </v-col>
                <v-col class="my-2 pa-1" cols="12" xs="12" sm="2" md="2" lg="2" xl="2">
                    <v-text-field label="Description" v-model="formLT.lookupTableDescription" filled hide-details background-color="white" dense></v-text-field>
                </v-col>
                <v-col class="my-2 pa-1" cols="12" xs="12" sm="2" md="2" lg="2" xl="2">
                    <v-text-field label="Link Table" v-model="formLT.lookupTableLinkTable" filled hide-details background-color="white" dense></v-text-field>
                </v-col>
                <v-col class="my-2 pa-1" cols="12" xs="12" sm="2" md="2" lg="2" xl="2">
                    <v-text-field label="Link Table Value" v-model="formLT.lookupTableLinkValue" filled hide-details background-color="white" dense></v-text-field>
                </v-col>
                <v-col class="my-2 pa-1" cols="12" xs="12" sm="2" md="2" lg="2" xl="2">
                   <v-btn @click="addLT" :height="buttonSizeDefault">Add Lookup Table</v-btn>
                </v-col>
            </v-row>
            <!-- // * LOOKUP TABLE FILTERS -->
            <v-row class="ma-0 mt-2 pa-0" no-gutters>
                <v-col cols="2" class="py-2 pl-4">
                    <v-text-field background-color="white" prepend-inner-icon="icons8-search" filled dense label="Search" v-model="searchLT" clearable hide-details></v-text-field>
                </v-col>
            </v-row>
            <!-- // * LOOKUP TABLE TABLE -->
            <v-row class="ml-1 mr-1">
                <v-col cols="12">
                    <v-data-table
                        :headers="computedHeadersLT"
                        :items="computedTableDataLT"
                        item-key="name"
                        class="elevation-0"
                        :search="searchLT">
                        <template v-slot:[`item.actions`]="{ item }">
                              <v-icon @click="openItem(item)">mdi-chevron-right</v-icon>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>



            <!-- // * LOOKUP VALUES FORM -->
            <v-row class="ma-0 px-3" no-gutters>
                <v-col class="my-2 pa-1" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <h3 class="grey--text text--darken-1">LOOKUP VALUES</h3>
                </v-col>
                <v-col class="my-2 pa-1" cols="12" xs="12" sm="2" md="2" lg="2" xl="2">
                    <v-text-field label="Text" v-model="formLV.text" filled hide-details background-color="white" dense></v-text-field>
                </v-col>
                <v-col class="my-2 pa-1" cols="12" xs="12" sm="2" md="2" lg="2" xl="2">
                    <v-text-field label="Value" v-model="formLV.value" filled hide-details background-color="white" dense></v-text-field>
                </v-col>
                <v-col class="my-2 pa-1" cols="12" xs="12" sm="2" md="2" lg="2" xl="2">
                    <v-select label="Lookup Table" v-model="formLV.lookupTable" :items="computedTableDataLT" item-text="lookupTableName" item-value="id" dense filled hide-details background-color="white"></v-select>
                </v-col>
                <v-col class="my-2 pa-1" cols="12" xs="12" sm="2" md="2" lg="2" xl="2">
                   <v-btn @click="addLV" :height="buttonSizeDefault">Add Lookup Value</v-btn>
                </v-col>
            </v-row>
            <!-- // * LOOKUP VALUES FILTERS -->
            <v-row class="ma-0 mt-2 pa-0" no-gutters>
                <v-col cols="2" class="py-2 pl-4">
                    <v-text-field background-color="white" prepend-inner-icon="icons8-search" filled dense label="Search" v-model="searchLV" clearable hide-details></v-text-field>
                </v-col>
            </v-row>
            <!-- // * LOOKUP VALUES TABLE -->
            <v-row class="ml-1 mr-1">
                <v-col cols="12">
                    <v-data-table
                        :headers="computedHeadersLV"
                        :items="computedTableDataLV"
                        item-key="name"
                        class="elevation-0"
                        :search="searchLV">
                        <template v-slot:[`item.actions`]="{ item }">
                              <v-icon @click="openItem(item)">mdi-chevron-right</v-icon>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'; // Map Actions / Getters
    export default {
        // * DATA
        data: () => ({
            // SEARCH
            searchLT: '', // Search Lookup Table
            searchLV: '', // Search Lookup Value
            // TABLE DATA
            tableDataLT: [],
            tableDataLV: [],
            // TABLE HEADERS
            headersLT: [
                // hidden shows the initial value of the column - hidden or visible
                // hides determines if you want to hide the column on screen resize
                { text: 'ID', value: 'id', sortable: false, align: 'left', hidden: false, hide: false },
                { text: 'Enabled',  value: 'enabled', align: 'start', sortable: false, hidden: false, hide: false },
                { text: 'Name', value: 'lookupTableName', sortable: false, hidden: false, hide: false },
                { text: 'Description', value: 'lookupTableDescription', sortable: false, hidden: false, hide: false },
                { text: 'Link Table', value: 'lookupLinkTable', sortable: false, hidden: false, hide: false },
                { text: 'Link Value', value: 'lookupLinkValue', sortable: false, hidden: false, hide: false },
                { text: 'Created', value: 'createdDateTime', sortable: false, hidden: true, hide: true },
                { text: 'Created By', value: 'createdUserData', sortable: false, hidden: true, hide: true },
                { text: 'Last Modified', value: 'modifiedDateTime', sortable: false, hidden: true, hide: true },
                { text: 'Modified By', value: 'modifiedUserData', sortable: false, hidden: true, hide: true },
                { text: 'Actions', value: 'actions', sortable: false, sortable: false, hidden: false, align: 'center' },
            ],
            headersLV: [
                // hidden shows the initial value of the column - hidden or visible
                // hides determines if you want to hide the column on screen resize
                { text: 'ID', value: 'id', sortable: false, align: 'left', hidden: true, hide: true },
                { text: 'Enabled',  value: 'enabled', align: 'start', sortable: false, hidden: false, hide: false },
                { text: 'Text', value: 'text', sortable: false, hidden: false, hide: false },
                { text: 'Value', value: 'value', sortable: false, hidden: false, hide: false },
                { text: 'LookupTable', value: 'lookupTable', sortable: false, hidden: false, hide: false },
                { text: 'Created', value: 'createdDateTime', sortable: false, hidden: true, hide: true },
                { text: 'Created By', value: 'createdUserData', sortable: false, hidden: true, hide: true },
                { text: 'Last Modified', value: 'modifiedDateTime', sortable: false, hidden: true, hide: true },
                { text: 'Modified By', value: 'modifiedUserData', sortable: false, hidden: true, hide: true },
                { text: 'Actions', value: 'actions', sortable: false, sortable: false, hidden: false, align: 'center' },
            ],


            // FORMS
            formLTDefaults: {
                id: '',
                enabled: true,
                lookupTableName: '',
                lookupTableDescription: '',
                lookupLinkTable: '',
                lookupLinkValue: '',
                createdUserData: {},
                createdDateTime: '',
                modifiedUserData: {},
                modifiedDateTime: '',
            },
            formLT: {
                id: '',
                enabled: true,
                lookupTableName: '',
                lookupTableDescription: '',
                lookupLinkTable: '',
                lookupLinkValue: '',
                createdUserData: {},
                createdDateTime: '',
                modifiedUserData: {},
                modifiedDateTime: '',
            },
            formLVDefaults: {
                id: '',
                enabled: true,
                text: '',
                value: '',
                lookupTable: '',
                createdUserData: {},
                createdDateTime: '',
                modifiedUserData: {},
                modifiedDateTime: '',
            },
            formLV: {
                id: '',
                enabled: true,
                text: '',
                value: '',
                lookupTable: '',
                createdUserData: {},
                createdDateTime: '',
                modifiedUserData: {},
                modifiedDateTime: '',
            }


            // TODO ITEMS MARKED FOR REMOVAL
            //overviewProp: [],
            // columns: [],
            // projectHelp: projectHelpList,
        }),
        // * COMPUTED
        computed: {
            // Map Getters
            ...mapGetters({
                GET_panelLayout: 'GET_panelLayout',
            }),
            // Computed Table Headers
            computedHeadersLT() {
              var data = [];
              return (this.data = this.$filter(this.headersLT, {
                hidden: false, // filter headers based on initial hidden value
              }))
            },
            // Computed Table Data - for filtering and sorting
            computedTableDataLT() {
                var t = this;
                var tableDataLT = this.tableDataLT
                // Map the Fields to the Data Required, and Format Necessary Fields
                var tableDataLTList = tableDataLT.map(e => {
                    return {
                        id: e.id,
                        enabled: e.enabled,
                        lookupTableName: e.lookupTableName,
                        lookupTableDescription: e.lookupTableDescription,
                        lookupLinkTable: e.lookupLinkTable,
                        lookupLinkValue: e.lookupLinkValue,
                        createdDateTime: t.MIX_formatDateTime(e.createdDateTime, 'x', 'DD-MMM-YY HH:mm'),
                        createdUserData: e.createdUserData.userName,
                        modifiedDateTime: t.MIX_formatDateTime(e.modifiedDateTime, 'x', 'DD-MMM-YY HH:mm'),
                        modifiedUserData: e.modifiedUserData.userName,
                    };
                });
                // Sort the Table
                return this.$sortBy(tableDataLTList, 'lookupTableName')
            },
            // Computed Table Headers
            computedHeadersLV() {
              var data = [];
              return (this.data = this.$filter(this.headersLV, {
                hidden: false, // filter headers based on initial hidden value
              }))
            },
            // Computed Table Data - for filtering and sorting
            computedTableDataLV() {
                var t = this;
                var tableDataLV = this.tableDataLV
                // Map the Fields to the Data Required, and Format Necessary Fields
                var tableDataLVList = tableDataLV.map(e => {
                    return {
                        id: e.id,
                        enabled: e.enabled,
                        text: e.text,
                        value: e.value,
                        lookupTable: e.lookupTable,
                        createdDateTime: t.MIX_formatDateTime(e.createdDateTime, 'x', 'DD-MMM-YY HH:mm'),
                        createdUserData: e.createdUserData.userName,
                        modifiedDateTime: t.MIX_formatDateTime(e.modifiedDateTime, 'x', 'DD-MMM-YY HH:mm'),
                        modifiedUserData: e.modifiedUserData.userName,
                    };
                });
                // Sort the Table
                return this.$sortBy(tableDataLVList, 'text')
            },
        },
        // * WATCH
        watch: {
            formLT: {
                handler() {
                    var lookupTableName = this.formLT.lookupTableName.toUpperCase()
                    lookupTableName = lookupTableName.replace(/\s+/g, '')
                    // TODO NEED TO REMOVE SPECIAL CHARACTERS AS WELL - RYAN
                    this.formLT.id = lookupTableName
                }, deep: true
            },
            formLV: {
                handler() {
                    var value = this.formLV.text.toUpperCase()
                    value = value.replace(/\s+/g, '')
                    // TODO NEED TO REMOVE SPECIAL CHARACTERS AS WELL - RYAN
                    this.formLV.value = value
                }, deep: true
            }
        },
        // * METHODS
        methods: {
            ...mapActions(['ACT_openStaffDirectory']),
            // this function hides columns on page resize
            // addLT: async function () {
            //     var createDocumentResult = await this.MIX_createLookupTable('lookupTables', this.formLT)
            //     if (createDocumentResult.code === 1) {
            //         this.MIX_alert(1, 'Lookup Table Added', null, null); // Send Success Alert to User
            //         this.formLT = this.formLTDefaults;
            //     } else {
            //         this.MIX_alert(-1, 'Error Lookup Table Could Not be Added', null, createDocumentResult.error); // Send Error Alert to User
            //     }
            //     return;
            // },
            //
            // addLV: async function () {
            //     delete (this.formLV.id); // remove id on add
            //     var createDocumentResult = await this.MIX_createDocument('lookupValues', this.formLV)
            //     if (createDocumentResult.code === 1) {
            //         this.MIX_alert(1, 'Lookup Value Added', null, null); // Send Success Alert to User
            //         this.formLV = this.formLVDefaults;
            //     } else {
            //         this.MIX_alert(-1, 'Error Lookup Value Could Not be Added', null, createDocumentResult.error); // Send Error Alert to User
            //     }
            //     return;
            // }
          addLT: async function () {
            var lookupTableName = this.formLT.lookupTableName.toUpperCase()
            lookupTableName = lookupTableName.replace(/\s+/g, '')
            // TODO NEED TO REMOVE SPECIAL CHARACTERS AS WELL - RYAN
            this.formLT.id = lookupTableName
            var createDocumentResult = await this.MIX_createLookupTable('lookupTables', this.formLT)

            if (createDocumentResult.code === 1) {
              this.MIX_alert(1, 'Lookup Table Added', null, null); // Send Success Alert to User
              this.formLT = this.formLTDefaults;
            } else {
              this.MIX_alert(-1, 'Error Lookup Table Could Not be Added', null, createDocumentResult.error); // Send Error Alert to User
            }
            return;
          },

          addLV: async function () {
            delete (this.formLV.id); // remove id on add
            var createDocumentResult = await this.MIX_createDocument('lookupValues', this.formLV)
            if (createDocumentResult.code === 1) {
              this.MIX_alert(1, 'Lookup Value Added', null, null); // Send Success Alert to User
              this.formLV = this.formLVDefaults;
            } else {
              this.MIX_alert(-1, 'Error Lookup Value Could Not be Added', null, createDocumentResult.error); // Send Error Alert to User
            }
            return;
          }

        },
        // * MOUNTED
        mounted() {
            var t = this;
            var breadcrumbs = [];
            breadcrumbs.push ({ name: `Settings`, path: `/settings/lookups`})
            this.MIX_breadcrumbs(breadcrumbs);
            this.MIX_setPanelLayout(12, 0, false);
            // Lookup Tables LT
            this.$firebase.db.collection('lookupTables').onSnapshot(function(querySnapshot) {
                t.tableDataLT = [];
                querySnapshot.forEach(function(doc) {
                    var document = doc.data()
                    document.id = doc.id;
                    t.tableDataLT.push(document);
                });
            });
            // Lookup Tables LV
            this.$firebase.db.collection('lookupValues').onSnapshot(function(querySnapshot) {
                t.tableDataLV = [];
                querySnapshot.forEach(function(doc) {
                    var document = doc.data()
                    document.id = doc.id;
                    t.tableDataLV.push(document);
                });
            });
        },
    };
</script>

<style>
    .border-bottom-red { background-color: white; border-bottom: 2px solid var(--v-primary-base) !important; }
    .bottom { position: fixed; bottom: 30px; }
</style>
