import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

// auth
import createPersistedState from "vuex-persistedstate";

//alert component
import alertbox_store from "@/modules/alertbox/alertbox_store.js";

// * IMPORT MODULES
import fsDialog_store from '@/modules/fsDialog/fsDialog_store.js';
import puDialog_store from '@/modules/puDialog/puDialog_store.js';
import photoUpload_store from '@/modules/photoUpload/photoUpload_store.js';
import photoViewer_store from '@/modules/photoViewer/photoViewer_store.js';
import confirmBox_store from '@/modules/confirmBox/confirmBox_store.js';
import confirmation_store from '@/modules/confirmation/confirmation_store.js';

// * FIREBASE AUTH STORE
import firebase_auth_store from '@/modules/auth/firebase_auth_store.js';
import progressBar_store from "@/modules/progressBar/progressBar_store.js";

export default new Vuex.Store({
	state: {
		currentUser: null,
		mobileNav: false,
		upload_file_store:null,
		panelLayout: {
			leftPanel: 12,
			rightPanel: 0,
			show: false,
			title: '',
			dynamicComponent: ''
		},
		breadcrumbs: [],  // breadcrumbs for page header

		openDevice: {},
		openOrganisation: {},
		openSite: {},
		openStaffDirectory: {},

		lookupTables: [],
		lookupValues: [],

		chartDevice : null,
	},

	plugins: [createPersistedState()],

	getters: {
		GET_chartDevice(state){
			return state.chartDevice;
		},
		GET_upload_file_store(state){
			return state.upload_file_store;
		},
		GET_currentUser(state) {
			return state.currentUser;
		},
		GET_currentUserData(state) {
			return {
				userId: state.currentUser.id,
				userName: state.currentUser.userName,
				userEmail: state.currentUser.userEmail
			}
		},
		GET_mobileNav(state) {
			return state.mobileNav
		}, // GET MOBILE NAV
		GET_panelLayout(state) {
			return state.panelLayout
		}, // GET PANEL LAYOUT
		GET_breadcrumbs(state) {
			return state.breadcrumbs
		},

		GET_openDevice(state) {
			return state.openDevice
		}, // GET OPEN Device
		GET_openOrganisation(state) {
			return state.openOrganisation
		}, // GET OPEN Organisation
		GET_openSite(state) {
			return state.openSite
		}, // GET OPEN SITES
		GET_openStaffDirectory(state) {
			return state.openStaffDirectory
		}, // GET OPEN STAFF DIRECTORY

		GET_lookupValues: (state) => (lookupTable) => {
			return state.lookupValues.filter((lookupValue) => {
				return lookupValue.lookupTable === lookupTable
			})
		},

	},

	mutations: {
		SET_chartDevice(state,payload){
			state.chartDevice = payload;
		},
		SET_upload_file_store(state,payload){
			state.upload_file_store = payload;
		},
		SET_currentUser(state, payload) {
			state.currentUser = payload;
		},
		MUT_mobileNav(state, data) {
			state.mobileNav = data
		}, // SET MOBILE NAV
		MUT_panelLayout(state, data) {
			state.panelLayout = data
		}, // PANEL LAYOUT MUTATIONS
		// BREADCRUMBS
		MUT_breadcrumbs(state, data) {
			state.breadcrumbs = data
		},
		MUT_openDevice(state, data) {
			state.openDevice = data
		},
		MUT_openOrganisation(state, data) {
			state.openOrganisation = data
		},
		MUT_openSite(state, data) {
			state.openSite = data
		},
		MUT_openStaffDirectory(state, data) {
			state.openStaffDirectory = data
		},

		MUT_lookupTables(state, data) {
			state.lookupTables = data
		},
		MUT_lookupValues(state, data) {
			state.lookupValues = data
		},

	},

	actions: {
		ACT_mobileNav({commit}, payload) {
			commit('MUT_mobileNav', payload)
		}, // SET MOBILE NAV
		ACT_panelLayout({commit}, payload) {
			commit('MUT_panelLayout', payload);
		}, // LAYOUT PANEL ACTIONS
		// BREADCRUMBS
		ACT_breadcrumbs({commit}, payload) {
			commit('MUT_breadcrumbs', payload)
		},

		ACT_openDevice({commit}, payload) {
			commit('MUT_openDevice', payload)
		},
		ACT_openOrganisation({commit}, payload) {
			commit('MUT_openOrganisation', payload)
		},
		ACT_openSite({commit}, payload) {
			commit('MUT_openSite', payload)
		},
		ACT_openStaffDirectory({commit}, payload) {
			commit('MUT_openStaffDirectory', payload)
		},

		ACT_lookupTables({commit}, payload) {
			commit('MUT_lookupTables', payload)
		},
		ACT_lookupValues({commit}, payload) {
			commit('MUT_lookupValues', payload)
		},

	},

	modules: {
		alertbox_store: alertbox_store,
		fsDialog_store: fsDialog_store,
		puDialog_store: puDialog_store,
		photoUpload_store: photoUpload_store,
		photoViewer_store: photoViewer_store,
		progressBar_store: progressBar_store,
		firebase_auth_store: firebase_auth_store,
		confirmBox_store: confirmBox_store,
		confirmation_store: confirmation_store,
	}

})
