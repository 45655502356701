const confirmation_store = {
    namespaced: true, //to be case sencitive
    // * STATE
    state: {
        confirmation: {
            show: false,
            title: "Something",
            message: "Something",
            size: "290",
            trueButton: "YES" ,
            falseButton: "NO",
            actionDetails: {},
            decision: {
                answer: false 
            }
        },
        confirmationResult: null,
    },
    // * MUTATIONS
    mutations: {
        setConfirmation (state,data) { state.confirmation = data },
        setDecision (state,data) { state.confirmation.decision = data },
        MUT_confirmationResult (state, data) { state.confirmationResult = data },
    },
    // * GETTERS
    getters: {
        currentConfirmation (state) { return state.confirmation },
        currentDecision (state) { return state.confirmation.decision }, // TODO REMOVE ALL INSTANCES THROUGH CODEBASE
        GET_confirmationResult(state) { return state.confirmationResult },
    },
    // * ACTIONS
    ACT_confirmationResult({commit}, payload){ commit('MUT_confirmationResult', payload) },

};
export default confirmation_store;