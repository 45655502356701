<template>
    <div class="" style="width: 100% !important;">
        <apexchart :options="chartOptions" :height="resizeChartHeight()"  :series="series"></apexchart>
    </div>
</template>

<script>
export default {
    props: {
        width: { type: String, default: '100%' },
        height: { type: String, default: '100%' },
        title: { type: String, default: 'Chart Title' },
        chartdata: { type: Object, default: function() { return {} } },
        loaded: { type: Boolean, default: false },
    },
    // mounted () {
    //     this.renderChart(this.chartData, this.options)
    

    data: () => ({
        chartOptions: {
            colors: ['#9c27b0'], // Series Colours
            chart: {
                type: 'area',
                zoom: { 
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true
                },
                toolbar: { autoSelected: 'zoom' },
                fill: { colors: ['#9c27b0'] },
            },
            dataLabels: { enabled: false },
            markers: {
               colors: ['#9c27b0']
            },
            xaxis: {
                type: 'datetime'
            },            
            yaxis: {
                labels: {
                    formatter: function (val) {
                        return (val).toFixed(0);
                    }
                }
            },
            tooltip: {
                shared: false,
                x: {
                    format: 'ddd dd/MM HH:mm:ss',
                }
            },
            fill: { colors: ['#9c27b0'] },
            stroke: { curve: 'smooth',  width: 1, colors: ['#9c27b0'] },
            title: { text: '', align: 'left' },
        },
        series: []
    }),
    computed:{
       
    },
    methods: {
         resizeChartHeight(){
            if(this.$vuetify.breakpoint.height >= 1100){
                return this.$vuetify.breakpoint.height-400;
            }else{
                return this.$vuetify.breakpoint.height-400
            }
        },
        async updateChart() {
            this.series = [];
            var chartData = {};
            chartData.name = this.title;
            chartData.data = await this.formatChartData(this.chartdata.data, 'time', 'value', 'YYYY-MM-DDTHH:mm:ss.SSSZ', 'x');
            this.series.push (chartData);
        },
        formatChartData: function(chartData, xField, yField, dateFormatIn, dateFormatOut) {
            return new Promise(resolve => {
                var formatedData = []
                // console.log('format chartData = ' + chartData?.length)
                chartData.forEach(data => {
                    var x = data[xField]
                    var y = data[yField]
                    if (xField === 'time') {
                        x = this.$moment(x, dateFormatIn).format(dateFormatOut)
                    }
                    x = parseInt(x)
                    formatedData.push([x, y])
                })
                resolve (formatedData)
            })
        }
    },
    mounted () {
        this.chartOptions.title.text = this.title // save prop into title
        this.chartOptions.chart.height = this.height;
        this.chartOptions.chart.width = this.width;
        this.chartOptions.chart.fill.colors[0] = this.$vuetify.theme.themes.light.primary  
        this.updateChart();
    }
}
</script>

<style>
/* Hide the export CSV option from the Graph */
.apexcharts-menu-item.exportCSV {
  display: none;
}
</style>