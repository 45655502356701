// * PHOTOUPLOAD STORE MODULE
const photoUpload_store = {
    namespaced: true,
    // * STATE
    state: {
        photoUpload: {
            allowedTypes: '',
            folder: '',
            type: '',
            typeId: '',
        },
        photoUploadResult: null,
    },
    // * MUTATIONS
    mutations: {
        MUT_photoUpload(state, data) { state.photoUpload = data },
        MUT_photoUploadResult(state, data) { state.photoUploadResult = data },
    },
    // * GETTERS
    getters: {
        GET_photoUpload(state) { return state.photoUpload },
        GET_photoUploadResult(state) { return state.photoUploadResult },
    },
    // * ACTIONS
    actions: {
        ACT_photoUpload({commit}, payload){ commit('MUT_photoUpload', payload) },
        ACT_photoUploadResult({commit}, payload){ commit('MUT_photoUploadResult', payload) },
    }    

};
export default photoUpload_store;
