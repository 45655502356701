import { mapActions } from 'vuex';
const photoUpload_mixins = {
    methods: {
        ...mapActions({
            ACT_photoUpload: 'photoUpload_store/ACT_photoUpload',
            ACT_photoUploadResult: 'photoUpload_store/ACT_photoUploadResult'
        }),
        MIX_photoUpload (payload) {
            this.ACT_photoUpload (payload);
        },
        MIX_photoUploadResult (payload){
            this.ACT_photoUploadResult (payload);
        },
    }
}
export default {
    install (Vue, options) {
        Vue.mixin(photoUpload_mixins)
    }
};
