<template>
    <div class="pa-4" style="width:100%">

        <pageLoader :is-page-loading="isPageLoading"/>

        <!--Header-->
        <div v-if="!computedIsPageLoading"
             class="d-flex align-center"
             :class="$vuetify.breakpoint.width > 768 ? 'justify-space-between text-h4' : $vuetify.breakpoint.width <= 400 ? 'justify-center text-h6' : 'justify-space-between text-h6'">

            <!--Back Button-->
            <app-btn v-if="$vuetify.breakpoint.width > 600"
                     @click.native="MIX_go('/')"
                     class="back-button mr-4"
                     icon="icons8-back-to"/>

            <!--Device Name | Site Name-->
            <div v-if="$vuetify.breakpoint.width > 600" class="d-flex flex-column">

                <app-text category="text-xlarge-bold" class="primary--text">{{ selectedDevice.siteName }}</app-text>

                <v-select background-color="white"
                          class="rounded-lg mt-2"
                          filled
                          flat
                          hide-details
                          :items="deviceCollectionData"
                          item-text="deviceName"
                          outlined
                          return-object
                          v-model="selectedDevice">
                    <template v-slot:selection="{item}">
                        <app-text category="text-medium" class="grey--text">{{ item.deviceName }}</app-text>
                    </template>
                </v-select>

            </div>
            <div v-else style="width: 100%">

                <app-text category="text-xlarge-bold" class="primary--text">{{ selectedDevice.siteName }}</app-text>

                <v-select background-color="white"
                          class="rounded-lg mt-2"
                          filled
                          flat
                          hide-details
                          :items="deviceCollectionData"
                          item-text="deviceName"
                          outlined
                          return-object
                          v-model="selectedDevice">
                    <template v-slot:selection="{item}">
                        <app-text category="text-medium" class="grey--text">{{ item.deviceName }}</app-text>
                    </template>
                </v-select>

            </div>

            <v-spacer v-if="$vuetify.breakpoint.width > 600"/>

            <!--Company logo-->
            <div>
                <v-img v-if="$vuetify.breakpoint.width > 768"
                       :max-width="$vuetify.breakpoint.width > 768 ? 288 : $vuetify.breakpoint.width > 400 ? 140 : 140"
                       :max-height="44"
                       :src="require('@/assets/company_logo.svg')"/>
            </div>

        </div>

        <v-divider class="mt-4"/>

        <!--Tabs-->
        <v-tabs background-color="white"
                class="rounded-lg mt-4"
                color="primary"
                dark
                grow
                show-arrows
                v-model="tab">

            <v-tab @click.native="chartKey++"
                   v-for="item in categoryArray" :key="item.tab"
                   class="primary--text"
                   style="width: 25%">
                {{ item }}
            </v-tab>

        </v-tabs>

        <!--Tabs Content-->
        <v-tabs-items v-model="tab" class="appbackground mt-4">
            <v-tab-item v-for="item in categoryArray" :key="item.tab">

                <!--Dashboard-->
                <div v-if="tab===0" class="charts-dashboard-container">

                    <v-card v-for="sensor in sensors" :key="sensor.id"
                            class="pa-4 rounded-lg"
                            flat
                            style="height: 264px !important;">

                        <!--Sensor Type | UOM | Time Ago-->
                        <div class="d-flex align-center justify-space-between">

                            <!--Sensor Type | UOM-->
                            <app-text category="text-default-bold" class="primary--text">
                                {{ sensor.sensorType }}
                                <span class="title grey--text text--darken-1">{{ sensor.unit }}</span>
                            </app-text>

                            <!--Time Ago-->
                            <app-text category="text-small" class="grey--text">
                                {{ MIX_fromNow(sensor.updated, "YYYY-MM-DDTHH:mm:ss.Z", false) }}
                            </app-text>

                        </div>

                        <!--Graphic-->
                        <div class="d-flex align-center justify-center mt-4">
                            <component v-if="sensor.value"
                                       :chartdata="sensor.value"
                                       :is="sensor.component"
                                       :key="chartKey"
                                       :limits="sensorLimits"
                                       :sensortype="sensor.sensorType"
                                       :thresholds="sensorThresholds"
                                       :title="active.measurement"/>
                        </div>

                    </v-card>

                </div>

                <!--Chart-->
                <div v-else-if="tab===1">

                    <v-card class="pa-4 rounded-lg"
                            flat>

                        <!--Sensor Selection | Time Filter-->
                        <div :class="$vuetify.breakpoint.width >= 696
                                        ? 'd-flex justify-space-between align-center'
                                        : 'd-flex flex-column'">

                            <!--Sensor Selection-->
                            <div class="primary--text font-weight-bold">
                                <v-select class="rounded-lg"
                                          hide-details="auto"
                                          :items="sensors"
                                          item-text="sensorType"
                                          item-value="sensorType"
                                          label="Sensor"
                                          outlined
                                          return-object
                                          v-model="active.measurement"/>
                            </div>

                            <!--Time Filters-->
                            <v-btn-toggle class="rounded-lg"
                                          :class="$vuetify.breakpoint.width < 696 && 'mt-4'"
                                          v-model="toggleDate">
                                <v-btn @click="active.timeValue = '2'; active.timeUnit = 'hours'; updateGraph();"
                                       class="grey lighten-2 primary--text font-weight-bold"
                                       depressed
                                       dark
                                       style="width: 20%;">2H
                                </v-btn>
                                <v-btn @click="active.timeValue = '24';active.timeUnit = 'hours'; updateGraph();"
                                       class="grey lighten-2 primary--text font-weight-bold"
                                       depressed
                                       dark
                                       style="width: 20%;">24H
                                </v-btn>
                                <v-btn @click="active.timeValue = '2';	active.timeUnit = 'days'; updateGraph();"
                                       class="grey lighten-2 primary--text font-weight-bold"
                                       depressed
                                       dark
                                       style="width: 20%;">2D
                                </v-btn>
                                <v-btn @click=" active.timeValue = '7'; active.timeUnit = 'days'; updateGraph();"
                                       class="grey lighten-2 primary--text font-weight-bold"
                                       depressed
                                       dark
                                       style="width: 20%;">7D
                                </v-btn>
                                <v-btn @click=" active.timeValue = '30'; active.timeUnit = 'days'; updateGraph();"
                                       class="grey lighten-2 primary--text font-weight-bold"
                                       depressed
                                       dark
                                       style="width: 20%;">30D
                                </v-btn>
                            </v-btn-toggle>

                        </div>

                        <!--Showing Last...-->
                        <!--<app-text class="grey&#45;&#45;text mt-4">-->
                        <!--    Showing Last {{ active.timeValue }} {{ active.timeUnit }}-->
                        <!--</app-text>-->

                        <!--Action Buttons-->
                        <div class="d-flex align-center justify-center my-4">
                            <zoomable v-if="chartData"
                                      :chartdata="chartData"
                                      :key="graphKey"
                                      :loaded="chartLoaded"
                                      :title="`${active.measurement.sensorType} (${active.measurement.unit})`"/>
                        </div>

                    </v-card>

                </div>

                <!--Historical Data-->
                <div v-else-if="tab===2">

                    <v-card class="pa-4 rounded-lg"
                            flat>

                        <!--Sensor Selection | Time Filter-->
                        <div :class="$vuetify.breakpoint.width >= 752
                                        ? 'd-flex justify-space-between align-center'
                                        : 'd-flex flex-column'">

                            <!--Sensor Selection-->
                            <div class="primary--text font-weight-bold">
                                <v-select @change="updateTable()"
                                          class="rounded-lg"
                                          :class="$vuetify.breakpoint.width >= 752 && 'mr-4'"
                                          hide-details="auto"
                                          :items="sensors"
                                          item-text="sensorType"
                                          label="Sensor"
                                          outlined
                                          return-object
                                          v-model="historicDataSensorType"/>
                            </div>

                            <v-spacer/>

                            <!--Time Filters-->
                            <v-btn-toggle class="rounded-lg"
                                          :class="$vuetify.breakpoint.width < 752 && 'mt-4'"
                                          v-model="toggleHistoricDataDate">
                                <v-btn
                                    @click="active.timeValue = '2';	active.timeUnit = 'hours'; updateTable();"
                                    class="grey lighten-2 primary--text font-weight-bold"
                                    depressed
                                    dark
                                    style="width: 20%;">2H
                                </v-btn>
                                <v-btn @click="active.timeValue = '24';active.timeUnit = 'hours';updateTable();"
                                       class="grey lighten-2 primary--text font-weight-bold"
                                       depressed
                                       dark
                                       style="width: 20%;">24H
                                </v-btn>
                                <v-btn @click="active.timeValue = '2';	active.timeUnit = 'days';updateTable();"
                                       class="grey lighten-2 primary--text font-weight-bold"
                                       depressed
                                       dark
                                       style="width: 20%;">2D
                                </v-btn>
                                <v-btn @click="active.timeValue = '7';active.timeUnit = 'days';updateTable();"
                                       class="grey lighten-2 primary--text font-weight-bold"
                                       depressed
                                       dark
                                       style="width: 20%;">7D
                                </v-btn>
                                <v-btn @click="active.timeValue = '30';active.timeUnit = 'days';updateTable();"
                                       class="grey lighten-2 primary--text font-weight-bold"
                                       depressed
                                       dark
                                       style="width: 20%;">30D
                                </v-btn>
                            </v-btn-toggle>

                            <!--Export Button-->
                            <app-btn v-if="computedExportFlag"
                                     @click.native="MIX_exportDocuments(headersCSV,`${historicDataSensorType.sensorType} Readings`,formatExport)"
                                     :block="$vuetify.breakpoint.width < 752"
                                     :class="$vuetify.breakpoint.width >= 752 ? 'ml-4' : 'mt-4'"
                                     icon="icons8-export-csv"
                                     label="Export"/>

                        </div>

                        <v-data-table class="mt-4"
                                      :headers="historicDataHeaders"
                                      :items="tableData[0]"
                                      :items-per-page="10"
                                      mobile-breakpoint="1"
                                      sort-by="time"
                                      sort-desc>
                            <template v-slot:item.time="{ item }">
                                {{ $moment(item.time).format("DD/MM/YYYY HH:mm") }}
                            </template>
                        </v-data-table>

                    </v-card>

                </div>

                <!--Latest Sensor Readings-->
                <div v-else-if="tab===3">

                    <v-card
                        class="pa-4 rounded-lg"
                        flat>

                        <v-data-table :headers="headers"
                                      :items="sensors"
                                      :items-per-page="10"
                                      mobile-breakpoint="1">
                            <template v-slot:item.updated="{ item }">
                                {{ MIX_fromNow(item.updated, "YYYY-MM-DDTHH:mm:ss.Z", false) }}
                            </template>
                        </v-data-table>

                    </v-card>

                </div>

            </v-tab-item>
        </v-tabs-items>

    </div>
</template>

<script>
import semicircle_guage_component from "./charts/semicircle_guage_component.vue";
import stroked_guage_component from "./charts/stroked_guage_component.vue";
import zoomable_component from "./charts/zoomable_component.vue";
import axios from "axios";

export default {

    components: {
        zoomable: zoomable_component,
        strokedguage: stroked_guage_component,
        semicircleguage: semicircle_guage_component,
    },

    data: () => ({
        active: {
            measurement: "USAGE",
            timeValue: "24",
            timeUnit: "hours",
        },
        apiURL: "https://sensors-server.thinkair.co.uk/api/v1",
        categoryArray: ["DASHBOARD", "CHART", "HISTORICAL DATA", "LATEST SENSOR READINGS"],
        chartData: null,
        chartKey: 0,
        chartLoaded: false,
        deviceId: "",
        exportFlag: false,
        graphKey: 0,
        headersCSV: {
            time: 'Time',
            value: 'Value',
        },
        headers: [
            {text: "Type", value: "sensorType"},
            {text: "Value", value: "value"},
            {text: "Unit", value: "unit"},
            {text: "Updated", value: "updated"},
        ],
        historicDataHeaders: [
            {text: "Time", value: "time"},
            {text: "Value", value: "value"},

        ],
        historicDataSensorType: "Sensor",
        isPageLoading: true,
        loading: true,
        sensorId: "",
        sensorLimits: [],
        sensors: [],
        tab: null,
        tableData: [],
        thresholds: [],
        toggleDate: 1,
        toggleHistoricDataDate: 1,

        deviceCollectionData: [],
        isDeviceCollectionDataLoaded: false,

        organisationDevices: [],
        devicesCollectionData: [],
        selectedDevice: {},

    }),

    computed: {
        computedExportFlag() {
            return this.historicDataSensorType !== 'Sensor';
        },
        computedIsPageLoading() {
            const t = this
            t.isPageLoading = !(t.isDeviceCollectionDataLoaded)
            return t.isPageLoading;
        },
        formatExport() {
            const t = this
            const data = JSON.parse(JSON.stringify(t.tableData[0]));
            t.exportSensorReadings = [];

            for (let i = 0; i < data.length; i++) {
                const time = "";
                if (data[i].time) {
                    time = data[i].time
                    time = t.$moment(time).format("DD/MM/YYYY HH:mm")

                } else {
                    time = "UNKNOWN"
                }
                const value = "";

                if (data[i].value) {
                    value = data[i].value;
                } else {
                    value = "UNKNOWN"
                }

                t.exportSensorReadings.push({
                    time: time,
                    value: value,
                })
            }

            return t.exportSensorReadings;
        },
    },
    methods: {
        axiosGet: async function (url) {
            return await axios
                .get(url)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => console.log("ERROR " + error));
        },
        getSensorData: function () {
            return new Promise(async (resolve) => {
                let t = this;
                var timeseriesData = await t.axiosGet(`${t.apiURL}/sensor/mean/${t.selectedDevice.deviceId}?type=${t.active.measurement.sensorType}&timevalue=${t.active.timeValue}&timeunit=${t.active.timeUnit}`);
                //console.log(`${t.apiURL}/sensor/mean/${t.selectedDevice.deviceId}?type=${t.active.measurement.sensorType}&timevalue=${t.active.timeValue}&timeunit=${t.active.timeUnit}`)
                if (timeseriesData.code === 1) {
                    this.chartLoaded = true;
                    resolve(timeseriesData.data);
                }
            }); // console.log('id = ' + id)
        },
        getHistoricSensorData: function () {
            return new Promise(async (resolve) => {
                let t = this;
                var timeseriesData = await t.axiosGet(`${t.apiURL}/sensor/mean/${t.selectedDevice.deviceId}?type=${t.historicDataSensorType.sensorType}&timevalue=${t.active.timeValue}&timeunit=${t.active.timeUnit}`);
                if (timeseriesData.code === 1) {
                    this.chartLoaded = true;
                    resolve(timeseriesData.data);
                }
            }); // console.log('id = ' + id)
        },
        getLastValue: function (id, measurement) {
            return new Promise(async (resolve) => {
                let t = this;
                var lastValueResult = await t.axiosGet(`${t.apiURL}/sensor/last/${id}?type=${measurement}`);
                // console.log(`${t.apiURL}/sensor/last/${id}?type=${measurement}`);
                if (lastValueResult.code === 1) {
                    // this.chartLoaded = true;
                    resolve(lastValueResult.data[0]);
                }
            }); // console.log('id = ' + id)
        },

        updateGraph: async function () {
            let sensorDataResult = await this.getSensorData();
            this.chartData = {
                name: this.active.measurement,
                data: sensorDataResult,
            };
            this.graphKey++;
        },

        updateTable: async function () {
            this.tableData = []
            let sensorDataResult = await this.getHistoricSensorData();
            // For each element in the array set up to a max decimal value
            sensorDataResult.forEach( element => {
                element.value = parseFloat(element.value.toFixed(1))
            });
            this.tableData.push(sensorDataResult)
        },

        async getDeviceCollectionData() {
            const t = this;
            let deviceCollectionData = [];

            const collection = await t.$firebase.db.collection("devices").where("organisationId", "==", t.selectedDevice.organisationId);
            await collection.get().then((device) => {

                // Clear the Devices collection data to avoid duplications
                deviceCollectionData = [];

                device.forEach((doc) => {

                    const document = doc.data();
                    document.id = doc.id;

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty("delete")) {
                        deviceCollectionData.push(document);

                    }
                });
                t.deviceCollectionData = deviceCollectionData
                t.isDeviceCollectionDataLoaded = true;
            });

        },
        async updateTabs() {
            this.sensors = [];
            await this.getHistoricSensorData();

            // GET https://sensors-server.thinkair.co.uk/api/v1/sensor/last/A81758FFFE05591D?type=CO2

            // ### RAW LAST 2 HOURS
            // GET https://sensors-server.thinkair.co.uk/api/v1/sensor/mean/A81758FFFE05591D?type=CO2&timevalue=2&timeunit=hours

            let getSensorData = await this.getSensorData(this.selectedDevice.deviceId, "USAGE", "kWh", "Usage Values");

            this.chartData = {
                name: "bla",
                data: getSensorData,
            };
            //let getLastValue = await this.getLastValue('7162ae3a247f9f62', 'PM25');
            //    console.log('getLastValue = ' + JSON.stringify(getLastValue, null, 2))

            this.sensorThresholds = [
                 {
                    sensorType: "VOLTAGE",
                    min: 0,
                    max: 350,
                    unit: "v",
                    text: "",
                    class: "light-green darken-1",
                    color: "#7CB342",
                    description: ""
                },
                 {
                    sensorType: "USAGE",
                    min: 0,
                    max: 9999999,
                    unit: "kWh",
                    text: "",
                    class: "light-green darken-1",
                    color: "#7CB342",
                    description: ""
                },
                 {
                    sensorType: "LOAD",
                    min: 0,
                    max: 99999,
                    unit: "W",
                    text: "",
                    class: "light-green darken-1",
                    color: "#7CB342",
                    description: ""
                },
                 {
                    sensorType: "CURRENT",
                    min: 0,
                    max: 32,
                    unit: "A",
                    text: "",
                    class: "light-green darken-1",
                    color: "#7CB342",
                    description: ""
                },                                

                {
                    sensorType: "CO2",
                    min: 0,
                    max: 400,
                    unit: "ppm",
                    text: "Very Low",
                    class: "light-green darken-1",
                    color: "#7CB342",
                    description: "Normal background concentration in outdoor ambient air."
                },
                {
                    sensorType: "CO2",
                    min: 400,
                    max: 1000,
                    unit: "ppm",
                    text: "Low",
                    class: "green darken-1",
                    color: "#43A047",
                    description: "Concentrations typical of occupied indoor spaces with good air exchange."
                },
                {
                    sensorType: "CO2",
                    min: 1000,
                    max: 2000,
                    unit: "ppm",
                    text: "Medium",
                    class: "orange darken-1",
                    color: "#FB8C00",
                    description: "Complaints of drowsiness and poor air."
                },
                {
                    sensorType: "CO2",
                    min: 2000,
                    max: 5000,
                    unit: "ppm",
                    text: "High",
                    class: "red darken-1",
                    color: "#E53935",
                    description: "Headaches, sleepiness and stagnant, stale, stuffy air. Poor concentration, loss of attention, increased heart rate and slight nausea may also be present."
                },
                {
                    sensorType: "CO2",
                    min: 5000,
                    max: 40000,
                    unit: "ppm",
                    text: "Very High",
                    class: "red darken-2",
                    color: "#C62828",
                    description: "Workplace exposure limit (as 8-hour TWA) in most jurisdictions."
                },
                {
                    sensorType: "CO2",
                    min: 40000,
                    max: 99999,
                    unit: "ppm",
                    text: "Extremly High",
                    class: "red darken-3",
                    color: "#B71C1C",
                    description: "Exposure may lead to serious oxygen deprivation resulting in permanent brain damage, coma, even death."
                },


                {
                    sensorType: "PM25",
                    min: 0,
                    max: 25,
                    unit: "µg/m3",
                    text: "Good",
                    class: "light-green darken-1",
                    color: "#7CB342",
                    description: "Complaints of drowsiness and poor air."
                },
                {
                    sensorType: "PM25",
                    min: 25,
                    max: 35,
                    unit: "µg/m3",
                    text: "Satifactory",
                    class: "green darken-1",
                    color: "#43A047",
                    description: "Headaches, sleepiness and stagnant, stale, stuffy air. Poor concentration, loss of attention, increased heart rate and slight nausea may also be present."
                },
                {
                    sensorType: "PM25",
                    min: 35,
                    max: 40,
                    unit: "µg/m3",
                    text: "Moderate",
                    class: "orange darken-1",
                    color: "#FB8C00",
                    description: "Workplace exposure limit (as 8-hour TWA) in most jurisdictions."
                },
                {
                    sensorType: "PM25",
                    min: 40,
                    max: 122,
                    unit: "µg/m3",
                    text: "Poor",
                    class: "red darken-1",
                    color: "#E53935",
                    description: "Exposure may lead to serious oxygen deprivation resulting in permanent brain damage, coma, even death."
                },
                {
                    sensorType: "PM25",
                    min: 122,
                    max: 250,
                    unit: "µg/m3",
                    text: "Very Poor",
                    class: "red darken-2",
                    color: "#C62828",
                    description: "Workplace exposure limit (as 8-hour TWA) in most jurisdictions."
                },
                {
                    sensorType: "PM25",
                    min: 250,
                    max: 99999,
                    unit: "µg/m3",
                    text: "Severe",
                    class: "red darken-3",
                    color: "#B71C1C",
                    description: "Exposure may lead to serious oxygen deprivation resulting in permanent brain damage, coma, even death."
                },


                {
                    sensorType: "TEMPERATURE",
                    min: 0,
                    max: 14,
                    unit: "°C",
                    text: "Too Cold",
                    class: "blue darken-1",
                    color: "#1E88E5",
                    description: "Workplace exposure limit (as 8-hour TWA) in most jurisdictions."
                },
                {
                    sensorType: "TEMPERATURE",
                    min: 14,
                    max: 19,
                    unit: "°C",
                    text: "Cold",
                    class: "blue darken-3",
                    color: "#1565C0",
                    description: "Exposure may lead to serious oxygen deprivation resulting in permanent brain damage, coma, even death."
                },
                {
                    sensorType: "TEMPERATURE",
                    min: 19,
                    max: 24,
                    unit: "°C",
                    text: "Comfortable",
                    class: "green darken-1",
                    color: "#43A047",
                    description: "Exposure may lead to serious oxygen deprivation resulting in permanent brain damage, coma, even death."
                },
                {
                    sensorType: "TEMPERATURE",
                    min: 24,
                    max: 45,
                    unit: "°C",
                    text: "Too Hot",
                    class: "red darken-1",
                    color: "#C62828",
                    description: "Exposure may lead to serious oxygen deprivation resulting in permanent brain damage, coma, even death."
                },
                {
                    sensorType: "HUMIDITY",
                    min: 0,
                    max: 25,
                    unit: "%",
                    text: "Dry",
                    class: "yellow darken-1",
                    color: "#FDD835",
                    description: "Low Humidity, Uncomfotably Dry"
                },
                {
                    sensorType: "HUMIDITY",
                    min: 25,
                    max: 30,
                    unit: "%",
                    text: "Fair",
                    class: "light-green darken-1",
                    color: "#7CB342",
                    description: "Fair Humidity Levels"
                },
                {
                    sensorType: "HUMIDITY",
                    min: 30,
                    max: 60,
                    unit: "%",
                    text: "Comfortable",
                    class: "green darken-1",
                    color: "#43A047",
                    description: "Comfortable Humidity"
                },
                {
                    sensorType: "HUMIDITY",
                    min: 60,
                    max: 70,
                    unit: "%",
                    text: "Fair",
                    class: "blue darken-1",
                    color: "#1E88E5",
                    description: "Fair Humidity Levels"
                },
                {
                    sensorType: "HUMIDITY",
                    min: 70,
                    max: 100,
                    unit: "%",
                    text: "Wet",
                    class: "blue darken-3",
                    color: "#1565C0",
                    description: "High Humidity Levels"
                },
                {
                    sensorType: "LIGHT",
                    min: 0,
                    max: 10,
                    unit: "Lux",
                    text: "Dark",
                    class: "grey darken-1",
                    color: "#000000",
                    description: "Its Dark"
                },
                {
                    sensorType: "LIGHT",
                    min: 10,
                    max: 1000,
                    unit: "Lux",
                    text: "Light",
                    class: "yellow darken-1",
                    color: "#FDD835",
                    description: "Its Light"
                },

                {
                    sensorType: "BATTERY",
                    min: 0,
                    max: 0.9,
                    unit: "VDD",
                    text: "Very Low",
                    class: "red darken-2",
                    color: "#C62828",
                    description: "Battery Very Low"
                },
                {
                    sensorType: "BATTERY",
                    min: 0.9,
                    max: 1.8,
                    unit: "VDD",
                    text: "Low",
                    class: "yellow darken-1",
                    color: "#FDD835",
                    description: "Battery Low"
                },
                {
                    sensorType: "BATTERY",
                    min: 1.8,
                    max: 2.7,
                    unit: "VDD",
                    text: "Ok",
                    class: "green darken-1",
                    color: "#43A047",
                    description: "Battery Ok"
                },
                {
                    sensorType: "BATTERY",
                    min: 2.7,
                    max: 3.6,
                    unit: "VDD",
                    text: "High",
                    class: "light-green darken-1",
                    color: "#7CB342",
                    description: "Battery High"
                },
            ];
            this.sensorLimits = [
                {sensorType: "USAGE", unit: "kWh", min: 0, max: 300, component: "strokedguage"},
                {sensorType: "VOLTAGE", unit: "V", min: 0, max: 250, component: "strokedguage"},
                {sensorType: "CURRENT", unit: "A", min: 0, max: 32, component: "strokedguage"},
                {sensorType: "LOAD", unit: "W", min: 0, max: 10000, component: "strokedguage"},
                {sensorType: "CO2", unit: "ppm", min: 0, max: 5000, component: "strokedguage"},
                {sensorType: "PM25", unit: "μm", min: 0, max: 250, component: "strokedguage"},
                {sensorType: "TEMPERATURE", unit: "°C", min: 0, max: 30, component: "semicircleguage"},
                {sensorType: "HUMIDITY", unit: "%", min: 0, max: 100, component: "semicircleguage"},
                {sensorType: "LIGHT", unit: "Lux", min: 0, max: 500, component: "semicircleguage"},
                {sensorType: "PRESSURE", unit: "mbar", min: 0, max: 500, component: "semicircleguage"},
                {sensorType: "BVOC", unit: "ppm", min: 0, max: 500, component: "semicircleguage"},
                {sensorType: "PMSIZE", unit: "µg/m3", min: 0, max: 500, component: "semicircleguage"},
                {sensorType: "PM25COUNT", unit: "#/cm3", min: 0, max: 500, component: "semicircleguage"},
                {sensorType: "PM10", unit: "µg/m3", min: 0, max: 500, component: "semicircleguage"},
                {sensorType: "PM10COUNT", unit: "#/cm3", min: 0, max: 500, component: "semicircleguage"},
                {sensorType: "PM1", unit: "µg/m3", min: 0, max: 500, component: "semicircleguage"},
                {sensorType: "PM1COUNT", unit: "#/cm3", min: 0, max: 500, component: "semicircleguage"},

                {sensorType: "BATTERY", unit: "vdd", min: 0, max: 3.6, component: "semicircleguage"},
            ];
            this.active.measurement = this.sensorLimits[0];
            for (let i = 0; i < this.sensorLimits.length; i++) {
                let getLastValueResult = await this.getLastValue(this.selectedDevice.deviceId, this.sensorLimits[i].sensorType);
                if (getLastValueResult !== undefined) {
                    let getLastValue = getLastValueResult;
                    let sensor = {};
                    sensor.sensorType = this.sensorLimits[i].sensorType;
                    sensor.unit = this.sensorLimits[i].unit;
                    sensor.value = getLastValue.value.toString();
                    sensor.updated = getLastValue.time;
                    sensor.component = this.sensorLimits[i].component;
                    this.sensors.push(sensor);


                }
            }
        }

    },

    watch: {
        active: {
            handler: function () {
                this.updateGraph();
            },
            deep: true,
        },
        selectedDevice: {
            handler: function () {
                this.historicDataSensorType = "Sensor"
                this.tableData = [];
                this.sensors = [];
                this.updateTabs();
            },
            deep: true,
        },
    },

    async mounted() {
        // this.deviceId = this.selectedDevice
        await this.getDeviceCollectionData();
        setInterval(() => {
            this.updateTabs();
        }, 30000);


    },
    created() {
        this.selectedDevice = this.$store.getters.GET_chartDevice
    }


};
</script>

<style>
.back-button .v-btn__content .v-icon {
    margin-right: 0 !important;
}

.charts-dashboard-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(256px, 1fr));
    grid-gap: 16px;
}
</style>
