import { render, staticRenderFns } from "./myOrganisation.vue?vue&type=template&id=baffb82e&scoped=true&"
import script from "./myOrganisation.vue?vue&type=script&lang=js&"
export * from "./myOrganisation.vue?vue&type=script&lang=js&"
import style0 from "./myOrganisation.vue?vue&type=style&index=0&id=baffb82e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "baffb82e",
  null
  
)

export default component.exports