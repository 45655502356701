<!--
RESTRICTED

Renders an icon and wording to inform the user that the current page selection is denied
-->
<template>
	<!--<v-layout justify-center>-->
		<v-container flat class="text-center pa-4">

			<div class="">

				<!--No access icon-->
				<v-icon color="primary" class="vicon-noaccess">icons8-no-access</v-icon>

				<v-divider class="primary mt-4"/>

				<!--Title-->
				<app-text category="text-xxlarge" class="justify-center font-weight-bold primary--text my-4">Access denied</app-text>

				<!--Prompt-->
				<app-text category="text-medium" class="justify-center mb-4">You are not authorized to access this page.</app-text>
				<app-text category="text-medium" class="justify-center">Please go back to return to home.</app-text>

			</div>

		</v-container>
	<!--</v-layout>-->
</template>

<script>
export default {
	name: "RestrictedPage",
	data() {
		return {};
	},
	computed: {},
	methods: {
		goToLink: function () {
			this.$router.push("/").catch((err) => {
				console.log(err);
			});
		},
	},
};
</script>

<style>
.vicon-noaccess {
	font-size: 130px !important;
}
</style>
