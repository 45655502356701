<!-- The confirmBox component -->
<template>
    <v-dialog v-model="confirmation.show" width="400">
      <v-card>
        <v-card-title class="primary headline white--text">
          {{confirmation.title}}
        </v-card-title>
        <v-card-text class="ma-0 subtitle-2 py-10">
            {{confirmation.message}}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions  class="justify-center pa-0">
          <v-btn class="ml-7" text @click="updateDecisionToFalse">
            {{confirmation.falseButton}}
          </v-btn>
          <v-divider class="mx-10" vertical></v-divider>
          <v-btn class="mr-7" text @click="updateDecisionToTrue">
            {{confirmation.trueButton}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
    data: () => ({
        dialog:true,
        confirmation: {
            show:false,
            title:'hi',
            message:'',
            size: "290",
            trueButton: "YES",
            falseButton:"NO",
        },
        decision:{
            anwser:false,
        }
    }),
     computed: {
    ...mapState('confirmation_store/', {
        confirmBox: state => state.confirmation,
        confirmBoxResult: state => state.decision
    })
    //...mapGetters//mapAction  GET_
  },
  watch:{
    confirmBox: {
        handler:function(){
          this.confirmation = this.confirmBox;
      },
      deep:true
    },
    confirmBoxResult:{
        handler:function(){
            this.decision = this.confirmBoxResult;
        },
        deep:true
    },
  },
  methods: {
    updateDecisionToTrue: async function(){
        this.MIX_decisionConfirmation(true);
        this.confirmation.show = false;
        var action = this.confirmation.actionDetails;
        if(action.actionName === 'delete'){
          var deleteDocumentResult = await this.MIX_deleteDocument(action.collection, action.itemId);
          this.MIX_removeFromNestedArray(action.parentCollection, action.parentId, action.collection, action.itemId);
          //MIX_removeFromNestedArray (collectionName, docId, removeFrom, removeId)
          if (deleteDocumentResult.code === 1) {
            var message = "Successfully " + action.actionName.slice(0, -1) + 'ed item.';
            this.MIX_alert(1, message, null, null)
          } else {
            var error = 'Error ' + action.actionName.slice(0, -1) + 'ing item.'
            this.MIX_alert(-1, error, null, null); // Send Error Alert to Site
          }
        }

    },
     updateDecisionToFalse(){
        this.MIX_decisionConfirmation(false);
        this.confirmation.show = false;
    }
  }
}
</script>
