<template>
<div style="width: 100%">
	<v-container class="d-flex flex-column align-center white pa-0">
		<v-row
			class="d-flex justify-center align-center"
			no-gutters
			fill-height
		>
			<v-col cols="12" class="d-flex align-center pa-0 ma-0">
				<mapbox
					:width="'96vw'"
					:height="'100vh'"
					class="maplayer"
					v-on:map-click="mapClick"
					:marker="marker"
					style="
						top: 0px !important;
						left: 0px !important;
					"
				></mapbox>
			</v-col>
		</v-row>
	</v-container>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import mapbox from "../../components/mapbox"

export default {
    name: 'MapPage',
    components: {
        mapbox,
    },
	// middleware: 'authenticated',
	data: () => ({
		device: {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [],
            },
            properties: {
                name: '',
                id: '',
                value: '',
                color: '#582768',
                icon: 'school-white',
                alert: 'false',
                textColor: '#582768',
                circle: 'true',
            },
		},
		isFullScreen: false,
		isFormValid: false,
		loading: true,
		marker: null,
	}),
	computed: {
		// ...mapGetters({
		// 	GET_authenticated: 'GET_authenticated',
		// 	GET_firebaseUser: 'GET_firebaseUser',
		// }),

	},
	methods: {
		mapClick(value) {
			// console.log("emitted click value", value)
			// alert(JSON.stringify(value));
		},

	},
};
</script>