<!--
Users

Renders the Users page which displays the details for users.
This renders as either a table (tablets and desktops) or cards (mobile).

-->
<template>
    <v-row no-gutters class="fill-height" style="width: 100% !important;">

        <!--Left panel-->
        <v-col :cols="GET_panelLayout.leftPanel" class="pa-4">

            <!--Page title-->
            <page-title icon="icons8-people" pageTitle="Users"/>

            <!--Searches-->
            <v-row no-gutters>

                <!--User Name-->
                <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
                       :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'">
                    <app-input input-type="textInput"
                               :append-icon="'icons8-search'"
                               label="User Name"
                               v-model="searchByUserName"/>
                </v-col>

                <!--Job Title-->
                <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
                       :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pl-2'">
                    <app-input input-type="textInput"
                               :append-icon="'icons8-search'"
                               label="Job Title"
                               v-model="searchByJobTitle"/>
                </v-col>

            </v-row>

            <!--Filter buttons | Action buttons-->
            <div v-if="['SA'].includes(GET_currentUser.userLevel)" class="mt-4">

                <!--Toggle filters | Action buttons-->
                <div class="d-flex">

                    <!--Access-->
                    <div v-if="$vuetify.breakpoint.width >= 600">

                        <!--Toggle buttons-->
                        <v-btn-toggle active-class="white"
                                      background-color="lightgrey"
                                      color="white"
                                      class="rounded-lg mr-4"
                                      multiple
                                      v-model="filter.userStatus">

                            <!--Pending-->
                            <v-btn value="PENDING" :height="buttonSizeDefault">
                                <v-icon color="error" class="icons8-inactive-state"/>
                                <app-text category="text-small" class="grey--text">
                                    {{ $t(`filters.pending`) }}
                                </app-text>
                            </v-btn>

                            <!--Approved-->
                            <v-btn value="APPROVED" :height="buttonSizeDefault">
                                <v-icon color="success" class="icons8-checkmark-yes"/>
                                <app-text category="text-small" class="grey--text">
                                    {{ $t(`filters.approved`) }}
                                </app-text>
                            </v-btn>

                            <!--Rejected-->
                            <v-btn value="REJECTED" :height="buttonSizeDefault"
                                   v-if="GET_panelLayout.leftPanel === 12 && $vuetify.breakpoint.width > 1044">
                                <v-icon color="darkgrey" class="icons8-cancel"/>
                                <app-text category="text-small" class="grey--text"
                                          v-if="GET_panelLayout.leftPanel === 12 && $vuetify.breakpoint.width > 1168">
                                    {{ $t(`filters.rejected`) }}
                                </app-text>
                            </v-btn>

                            <!--Suspended-->
                            <v-btn value="SUSPENDED" :height="buttonSizeDefault"
                                   v-if="GET_panelLayout.leftPanel === 12 && $vuetify.breakpoint.width > 1044">
                                <v-icon color="darkgrey" class="icons8-private"/>
                                <app-text category="text-small" class="grey--text"
                                          v-if="GET_panelLayout.leftPanel === 12 && $vuetify.breakpoint.width > 1168">
                                    {{ $t(`filters.suspended`) }}
                                </app-text>
                            </v-btn>

                            <!--Archived-->
                            <v-btn value="ARCHIVED" :height="buttonSizeDefault"
                                   v-if="GET_panelLayout.leftPanel === 12 && $vuetify.breakpoint.width > 1044">
                                <v-icon color="grey" class="icons8-xbox-a"/>
                                <app-text category="text-small" class="grey--text"
                                          v-if="GET_panelLayout.leftPanel === 12 && $vuetify.breakpoint.width > 1168">
                                    {{ $t(`filters.archived`) }}
                                </app-text>
                            </v-btn>

                        </v-btn-toggle>

                    </div>

                    <v-spacer/>

                    <!--Action buttons-->
                    <div class="d-flex">

                        <!--Columns-->
                        <app-btn v-if="$vuetify.breakpoint.width >= 646"
                                 @click.native="columnsDialog = true"
                                 color="primary"
                                 hide-label-below="810"
                                 icon="icons8-select-column"
                                 label="Columns"/>

                        <!--Export-->
                        <app-btn v-if="$vuetify.breakpoint.width >= 646"
                                 @click.native="MIX_exportDocuments(headersCSV,'Staff Directory',formatExport)"
                                 class="ml-4"
                                 color="primary"
                                 hide-label-below="810"
                                 icon="icons8-export-csv"
                                 label="Export"/>

                    </div>

                </div>

            </div>

            <!--Table - for larger-screened devices-->
            <v-data-table v-if="$vuetify.breakpoint.width >= 600"
                          class="rounded-lg mt-4"
                          :headers="computedHeaders"
                          :items="computedTableData"
                          item-key="id"
                          sort-by="userName">

                <!--Id-->
                <template v-slot:[`item.id`]="{ item }">
                    <app-text category="text-small">{{ item.id }}</app-text>
                </template>

                <!--User Status-->
                <template v-slot:[`item.userStatus`]="{ item }">
                    <div>
                        <v-icon v-if="item.userStatus === 'PENDING'"
                                class="icons8-inactive-state" color="error" size="32"/>
                        <v-icon v-if="item.userStatus === 'REJECTED'"
                                class="icons8-cancel" color="warning" size="32"/>
                        <v-icon v-if="item.userStatus === 'APPROVED'"
                                class="icons8-checkmark-yes" color="success" size="32"/>
                        <v-icon v-if="item.userStatus === 'SUSPENDED'"
                                class="icons8-private" color="error" size="32"/>
                        <v-icon v-if="item.userStatus === 'ARCHIVED'"
                                class="icons8-xbox-a" color="grey" size="32"/>
                    </div>
                </template>

                <!--User Name-->
                <template v-slot:[`item.userName`]="{ item }">
                    <app-text category="text-small">{{ item.userName }}</app-text>
                </template>

                <!--Position-->
                <template v-slot:[`item.userJobTitle`]="{ item }">
                    <app-text category="text-small">{{ item.userJobTitle }}</app-text>
                </template>

                <!--Telephone Number-->
                <template v-slot:[`item.userTelephone`]="{ item }">
                    <app-text category="text-small">{{ item.userTelephone }}</app-text>
                </template>

                <!--Email Address-->
                <template v-slot:[`item.userEmail`]="{ item }">
                    <app-text category="text-small">{{ item.userEmail }}</app-text>
                </template>

                <!--Registered-->
                <template v-slot:[`item.registered`]="{ item }">
                    <div class="text-left">
                        <v-icon v-if="item.authId !== null" color="success" class="icons8-verified-account"/>
                        <v-icon v-else color="grey" class="icons8-customer"/>
                    </div>
                </template>

                <!-- Action button -->
                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn @click="openItem(item.id, 'View', false)"
                           class="primary--text white pa-4" depressed>
                        <v-icon class="icons8-forward" color="primary"/>
                    </v-btn>
                </template>

            </v-data-table>

            <!--Mobile Cards - for smaller-screened devices-->
            <div v-if="$vuetify.breakpoint.width < 600">

                <app-text v-if="!computedTableData.length" class="d-flex justify-center text-center">
                    There are no Users to show
                </app-text>

                <staff-user-modal v-for="item in computedTableData" :key="item.id"
                                  :user="item"/>

            </div>

        </v-col>

        <!--Right panel-->
        <transition name="custom-classes-transition"
                    enter-active-class="animate__animated animate__fadeIn animated__faster"
                    leave-active-class="animate__animated animate__fadeOut animated__faster"
                    mode="out-in">
            <rightpanel></rightpanel>
            <!-- <component :is="dynamicComponent" :key="dynamicComponentKey"></component>      -->
        </transition>

        <!--Column Dialog Box-->
        <v-dialog v-model="columnsDialog" scrollable max-width="300px">
            <v-card class="rounded-lg">

                <!--Title-->
                <app-text category="text-medium" class="primary--text ma-4">Show/Hide Columns</app-text>

                <v-divider/>

                <!--List items-->
                <v-card-text>
                    <v-list>
                        <v-list-item v-for="(header, index) in headers.slice(0, -1)" :key="index">
                            <app-text category="text-default">
                                <v-checkbox color="grey darken-1" hide-details v-model="headers[index].hidden"
                                            :false-value="true" :true-value="false" :label="header.text"/>
                            </app-text>
                        </v-list-item>
                    </v-list>
                </v-card-text>

                <v-divider/>

                <!--Close button-->
                <v-card-actions class="text-right">
                    <v-spacer/>
                    <v-btn color="primary" text @click="columnsDialog = false">Close</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

    </v-row>
</template>

<script>
import {mapGetters, mapActions} from "vuex"
import staffUserModal from "./staffUserModal/staffUserModal"

export default {

    name: "StaffDirectory",

    components: {
        staffUserModal
    },

    data: () => ({
        title: "Staff Directory",
        collection: "staffDirectory",
        collectionItem: "staffDirectory",
        collectionItemTitle: "Users",
        columnsDialog: false,
        exportStaff: [],
        filter: {
            status: [],
            userStatus: [],
        },
        headers: [
            {
                text: "ID",
                value: "id",
                align: "start",
                sortable: false,
                hidden: true,
                hide: false,
                hasAccess: ['SA', 'SU']
            },
            {
                text: "Status",
                value: "userStatus",
                align: "center",
                sortable: false,
                hidden: false,
                hide: false,
                width: '69px',
            },
            {
                text: "Name",
                value: "userName",
                align: "start",
                sortable: false,
                hidden: false,
                hide: false,
            },
            {
                text: "Job Title",
                value: "userJobTitle",
                align: "start",
                sortable: false,
                hidden: false,
                hide: false,
            },
            {
                text: "Telephone Number",
                value: "userTelephone",
                align: "start",
                sortable: false,
                hidden: false,
                hide: false,
            },
            {
                text: "Email Address",
                value: "userEmail",
                align: "start",
                sortable: false,
                hidden: false,
                hide: false,
            },
            {
                text: "Registered",
                value: "registered",
                align: "center",
                sortable: false,
                hidden: true,
                hide: true,
            },
            {
                text: "",
                value: "actions",
                align: "right",
                sortable: false,
                hasAccess: ['SA'],
                hidden: false,
                hide: false,
                width: "96px",
            },
        ],
        headersCSV: {
            userName: 'User Name',
            userTelephone: 'User Telephone',
            userEmail: 'User Email',
            // userAddressLine1: 'Address Line 1',
            // userAddressLine2: 'Address Line 2',
            // userAddressLine3: 'Address Line 3',
            // userTown: 'User Town',
            // userCounty: 'User County',
            // userPostcode: 'User Postcode',
            userTitle: 'User Position',
            userType: 'User Type',
            userLevel: 'User Level',
            userRole: 'User Role',
            userStatus: 'Access',
        },
        search: "", // Table search,
        tableData: [],
        searchByUserName: '',
        searchByJobTitle: '',
    }),

    computed: {
        ...mapGetters({
            GET_panelLayout: "GET_panelLayout",
            GET_currentUser: "GET_currentUser"
        }),

        // Format of the Export File
        formatExport() {
            const t = this
            const staff = JSON.parse(JSON.stringify(t.computedTableData));
            t.exportStaff = [];

            for (let i = 0; i < staff.length; i++) {
                const userName = "";
                if (staff[i].userName) {
                    userName = staff[i].userName;
                } else {
                    userName = "UNKNOWN"
                }
                const userTelephone = "";
                if (staff[i].userTelephone) {
                    userTelephone = staff[i].userTelephone;
                } else {
                    userTelephone = "UNKNOWN"
                }
                const userEmail = "";
                if (staff[i].userEmail) {
                    userEmail = staff[i].userEmail;
                } else {
                    userEmail = "UNKNOWN"
                }
                // const userAddressLine1 = "";
                // if (staff[i].userAddress.userAddressLine1) {
                //     userAddressLine1 = staff[i].userAddress.userAddressLine1;
                // } else {
                //     userAddressLine1 = "UNKNOWN"
                // }
                // const userAddressLine2 = "";
                // if (staff[i].userAddress.userAddressLine2) {
                //     userAddressLine2 = staff[i].userAddress.userAddressLine2;
                // } else {
                //     userAddressLine2 = "UNKNOWN"
                // }
                // const userAddressLine3 = "";
                // if (staff[i].userAddress.userAddressLine3) {
                //     userAddressLine3 = staff[i].userAddress.userAddressLine3;
                // } else {
                //     userAddressLine3 = "UNKNOWN"
                // }
                // const userTown = "";
                // if (staff[i].userAddress.userTown) {
                //     userTown = staff[i].userAddress.userTown;
                // } else {
                //     userTown = "UNKNOWN"
                // }
                // const userCounty = "";
                // if (staff[i].userAddress.userCounty) {
                //     userCounty = staff[i].userAddress.userCounty;
                // } else {
                //     userCounty = "UNKNOWN"
                // }
                // const userPostcode = "";
                // if (staff[i].userAddress.userPostcode) {
                //     userPostcode = staff[i].userAddress.userPostcode;
                // } else {
                //     userPostcode = "UNKNOWN"
                // }
                const userTitle = "";
                if (staff[i].userJobTitle) {
                    userTitle = staff[i].userJobTitle;
                } else {
                    userTitle = "UNKNOWN"
                }
                const userType = "";
                if (staff[i].userType) {
                    userType = staff[i].userType;
                } else {
                    userType = "UNKNOWN"
                }
                const userLevel = "";
                if (staff[i].userLevel) {
                    userLevel = staff[i].userLevel;
                } else {
                    userLevel = "UNKNOWN"
                }
                const userRole = "";
                if (staff[i].userRole) {
                    userRole = staff[i].userRole;
                } else {
                    userRole = "UNKNOWN"
                }
                const userStatus = "";
                if (staff[i].userStatus) {
                    userStatus = staff[i].userStatus;
                } else {
                    userStatus = "UNKNOWN"
                }

                t.exportStaff.push({
                    userName: userName,
                    userTelephone: userTelephone,
                    userEmail: userEmail,
                    // userAddressLine1: userAddressLine1,
                    // userAddressLine2: userAddressLine2,
                    // userAddressLine3: userAddressLine3,
                    // userTown: userTown,
                    // userCounty: userCounty,
                    // userPostcode: userPostcode,
                    userTitle: userTitle,
                    userType: userType,
                    userLevel: userLevel,
                    userRole: userRole,
                    userStatus: userStatus,
                })
            }

            return t.exportStaff;
        },

        /**
         * Computed Headers
         *
         * Only display headers that are not set to hidden and the user has access to (via userLevel).
         *
         * @returns {[headers]} - filtered headers to render in the table
         */
        computedHeaders() {
            const t = this
            const headers = t.headers
            let filteredHeaders = []

            headers.forEach(header => {

                // Only Admins can view the OPEN button
                if (header.value === 'actions' && !header.hasAccess.includes(t.GET_currentUser.userLevel)) return

                // If the column is not hidden, show it
                if (!header.hidden) filteredHeaders.push(header)

            })

            return filteredHeaders
        },

        computedTableData() {
            const t = this
            let tableData = t.tableData

            // Filter by User Status
            if (t.filter.userStatus.length) {
                tableData = tableData.filter(item => t.filter.userStatus.includes(item.userStatus))
            }

            // Search by UserName
            if (t.searchByUserName) {
                tableData = tableData.filter(user =>
                    user.userName.toUpperCase().match(t.searchByUserName.toUpperCase()))
            }

            // Search by Job Title
            if (t.searchByJobTitle) {
                tableData = tableData.filter(user =>
                    user.userJobTitle.toUpperCase().match(t.searchByJobTitle.toUpperCase()))
            }

            // Sort in to alphabetical order
            tableData = tableData.sort((a, b) => {
                return a.userName > b.userName ? 1 : -1
            })

            return tableData
        },

    },

    methods: {
        ...mapActions({
            ACT_openItem: "ACT_openStaffDirectory",
            ACT_openStaffDirectory: "ACT_openStaffDirectory",
        }),

        async getTableData() {
            const t = this
            let collectionData = []

            await t.$firebase.db.collection('users')
                .onSnapshot(snapshot => {

                    // Rest array to avoid data duplication
                    collectionData = []

                    snapshot.forEach(doc => {

                        const document = doc.data()
                        document.id = doc.id

                        if (!document.hasOwnProperty("delete")) collectionData.push(document)
                    })
                    t.tableData = collectionData
                })
        },

        async openItem(id, process, popup) {
            const t = this

            // Only allow opening if the user has access
            if (['SA'].includes(t.GET_currentUser.userLevel)) {
                const item = {}
                item.process = process
                item.popup = popup

                // If this is NOT a new document, fetch the user data by id
                if (item.process !== "New") {
                    const itemResult = await t.MIX_readDocumentById("users", id)
                    item.data = itemResult.data
                } else {
                    item.data = null
                }

                t.ACT_openItem(item)

                if (t.$vuetify.breakpoint.lgAndDown) {
                    t.MIX_fsDialog(true, t.collectionItemTitle, t.collectionItem)
                    t.MIX_setPanelLayout(12, 0, false, "")
                } else {
                    t.MIX_setPanelLayout(6, 6, true, t.collectionItemTitle, t.collectionItem)
                    // * HIDE HEADERS
                    for (let i = 0; i < t.headers.length; i++) {
                        if (t.headers[i].hide === true) {
                            t.headers[i].hidden = true
                        }
                    }
                }
            }
        },

    },

    async mounted() {
        const t = this

        t.MIX_setPanelLayout(12, 0, false, "") // Hide Right Panel

        // Get Users collections data
        await t.getTableData()
    },

};
</script>
