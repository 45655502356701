<!--
TERMS & CONDITIONS

Renders the T&Cs page.
Header comes from App.vue.
-->
<template>
	<div class="d-flex flex-column align-center pa-4">

		<!--Instructions-->
		<app-text category="text-default">
			Click on the following links to read the policies.
		</app-text>

		<!--T&C-->
		<v-btn class="primary mt-4" href="" target="_blank" :height="buttonSizeDefault">Terms and Condition</v-btn>

		<!--Privacy Policy-->
		<v-btn class="primary mt-4" href="" target="_blank" :height="buttonSizeDefault">Privacy Policy</v-btn>

	</div>
</template>

<script>
export default {
	name: "PrivacyTermsConditions",
};
</script>
