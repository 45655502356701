// * COMMENT STORE MODULE
const puDialog_store = {
    namespaced: true, //to be case sencitive
    // * STATE
    state: {
        puDialog: { show: false, dynamicComponent: 'blank', width: '500px', height: '500px' },
    },
    // * MUTATIONS
    mutations:{
        MUT_puDialog(state, data) { state.puDialog = data },
    },
    // * GETTERS
    getters:{
        GET_puDialog(state) { return state.puDialog },
    },
    // * ACTIONS
    actions: {
        ACT_puDialog({commit}, payload){ commit('MUT_puDialog', payload) },
    }
};
export default puDialog_store;