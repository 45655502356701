<template>
    <div class="d-flex flex-column" style="width: 100%">

        <app-text category="text-medium">{{ title }}</app-text>

        <app-text category="text-small" class="grey--text">{{ instruction }}</app-text>

        <v-divider class="mt-2"/>

    </div>
</template>

<script>
export default {

    name: "formSectionTitle",

    props: ['instruction', 'title']

}
</script>

<style scoped>

</style>
